var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Base from './base';
var Loader = /** @class */ (function (_super) {
    __extends(Loader, _super);
    function Loader() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Loader.prototype.render = function () {
        return (React.createElement("svg", __assign({}, this.props, { className: this.cn(this.className, 'svg-icon_loader') }),
            React.createElement("g", { transform: "translate(-10,-10)" },
                React.createElement("circle", { cx: "50", r: "14.6458", cy: "41", fill: "#1d3f72", transform: "rotate(168.557 50 50)" },
                    React.createElement("animateTransform", { attributeName: "transform", type: "rotate", calcMode: "linear", values: "0 50 50;360 50 50", keyTimes: "0;1", dur: "2.1s", begin: "0s", repeatCount: "indefinite" }),
                    React.createElement("animate", { attributeName: "r", calcMode: "spline", values: "0;15;0", keyTimes: "0;0.5;1", dur: "2.1", keySplines: "0.2 0 0.8 1;0.2 0 0.8 1", begin: "0s", repeatCount: "indefinite" })),
                React.createElement("circle", { cx: "50", r: "0.354157", cy: "41", fill: "#5699d2", transform: "rotate(348.557 50.0001 50.0001)" },
                    React.createElement("animateTransform", { attributeName: "transform", type: "rotate", calcMode: "linear", values: "180 50 50;540 50 50", keyTimes: "0;1", dur: "2.1s", begin: "0s", repeatCount: "indefinite" }),
                    React.createElement("animate", { attributeName: "r", calcMode: "spline", values: "15;0;15", keyTimes: "0;0.5;1", dur: "2.1", keySplines: "0.2 0 0.8 1;0.2 0 0.8 1", begin: "0s", repeatCount: "indefinite" })))));
    };
    Loader.defaultProps = __assign({}, Base.defaultProps, { viewBox: '0 0 80 80', width: 80, height: 80 });
    return Loader;
}(Base));
export default Loader;
