import axios from 'axios';
import uuid from 'uuid/v4';
var RequestCancelToken = /** @class */ (function () {
    function RequestCancelToken() {
        var _this = this;
        this.tokens = new Map();
        this.create = function (key) {
            if (!key) {
                // tslint:disable-next-line no-parameter-reassignment
                key = uuid();
            }
            if (_this.tokens.has(key)) {
                console.warn('Cancel token is `%s` duplicate!', key);
            }
            _this.tokens.set(key, axios.CancelToken.source());
            return {
                remove: _this.remove.bind(_this, key),
                new: _this.new.bind(_this, key),
                get: _this.get.bind(_this, key)
            };
        };
    }
    RequestCancelToken.prototype.remove = function (key) {
        var token = this.tokens.get(key);
        if (token) {
            token.cancel("Token '" + key + "' canceled");
            this.tokens.delete(key);
        }
        return true;
    };
    RequestCancelToken.prototype.new = function (key) {
        var token = this.tokens.get(key);
        var result = axios.CancelToken.source();
        if (token) {
            this.remove(key);
        }
        this.tokens.set(key, result);
        return result.token;
    };
    RequestCancelToken.prototype.get = function (key) {
        return this.tokens.get(key);
    };
    return RequestCancelToken;
}());
export default new RequestCancelToken();
