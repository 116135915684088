var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Fragment, PureComponent } from 'react';
import * as classnames from 'classnames/bind';
import Loader from 'component/loader';
import style from './style.pcss';
var LazyList = /** @class */ (function (_super) {
    __extends(LazyList, _super);
    function LazyList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cn = classnames.bind(style);
        _this.state = {
            pending: false,
            elements: 0
        };
        _this.onClick = function (e) {
            e && e.preventDefault();
            if (!_this.state.pending) {
                _this.setState({ pending: true }, function () {
                    if (_this.props.onClick) {
                        _this.props.onClick(e);
                    }
                });
            }
        };
        return _this;
    }
    LazyList.getDerivedStateFromProps = function (props, state) {
        var result = __assign({}, state);
        if (Array.isArray(props.children)) {
            if (state.pending && state.elements !== props.children.length) {
                result.pending = false;
            }
            result.elements = props.children.length;
        }
        return result;
    };
    Object.defineProperty(LazyList.prototype, "elButton", {
        get: function () {
            if (this.props.total && Array.isArray(this.props.children)) {
                if (this.props.total > this.props.children.length) {
                    var props = {
                        onClick: this.onClick,
                        children: 'Load more',
                        className: this.cn('lazy-button', {
                            'lazy-button_pending': this.state.pending
                        })
                    };
                    if (this.state.pending) {
                        props.children = React.createElement(Loader, null);
                    }
                    return React.createElement("button", __assign({}, props));
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    LazyList.prototype.render = function () {
        if (this.props.children) {
            return (React.createElement(Fragment, null,
                React.createElement("div", { className: this.cn('lazy-list', this.props.className) }, this.props.children),
                this.elButton));
        }
        return null;
    };
    return LazyList;
}(PureComponent));
export default LazyList;
