var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component, Suspense } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import history from 'component/core/history';
import Loader from 'component/loader';
import PollsList from 'route/polls';
import ProgressList from 'route/progress';
import ProgressDetail from 'route/progress-detail';
import PublicProgressList from 'route/public-progress';
import PublicProgressDetail from 'route/public-progress-detail';
import Poll from 'route/poll';
import ProposalsList from 'route/proposals';
// Pages
import Login from 'route/login';
import CreateProposal from 'route/proposals/create';
import LoginAdmin from 'route/login-admin';
import NotFound from 'route/not-found';
import Layout from 'component/layout';
import Modal from 'component/modal';
var Routes = /** @class */ (function (_super) {
    __extends(Routes, _super);
    function Routes() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.previous = _this.props.location;
        _this.onClickCloseModal = function (e) {
            e && e.preventDefault();
            _this.props.history.push(_this.previous);
        };
        return _this;
    }
    Routes.prototype.componentDidUpdate = function (props) {
        var location = this.props.location;
        if (props.history.action !== 'POP' && (!location.state || !location.state.modal)) {
            this.previous = this.props.location;
        }
    };
    Object.defineProperty(Routes.prototype, "isModal", {
        get: function () {
            var location = this.props.location;
            if (location.pathname === this.previous.pathname) {
                return false;
            }
            return (location.state && location.state.modal && this.previous !== location);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Routes.prototype, "location", {
        get: function () {
            return this.isModal ? this.previous : this.props.location;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Routes.prototype, "elModal", {
        get: function () {
            if (this.isModal) {
                return (React.createElement(Modal, { onClickClose: this.onClickCloseModal },
                    React.createElement(Switch, null,
                        React.createElement(Route, { exact: true, path: "/login", component: Login }),
                        React.createElement(Route, { exact: true, path: "/proposal-create", component: CreateProposal }))));
            }
        },
        enumerable: true,
        configurable: true
    });
    Routes.prototype.render = function () {
        return (React.createElement(Layout, null,
            React.createElement(Helmet, { title: "Parliament", meta: [{
                        name: 'description',
                        content: ''
                    }, {
                        name: 'keywords',
                        content: ''
                    }, {
                        name: 'document-state',
                        content: 'dynamic'
                    }, {
                        name: 'robots',
                        content: 'all'
                    }] }),
            React.createElement(Suspense, { fallback: React.createElement(Loader, null) },
                React.createElement(Switch, { location: this.location },
                    React.createElement(Route, { exact: true, path: "/login", component: Login }),
                    React.createElement(Route, { exact: true, path: "/login-admin", component: LoginAdmin }),
                    React.createElement(Route, { exact: true, path: "/", component: PollsList }),
                    React.createElement(Route, { exact: true, path: "/progress", component: ProgressList }),
                    React.createElement(Route, { exact: true, path: "/progress/:id", component: ProgressDetail }),
                    React.createElement(Route, { exact: true, path: "/public-progress", component: PublicProgressList }),
                    React.createElement(Route, { exact: true, path: "/public-progress/:id", component: PublicProgressDetail }),
                    React.createElement(Route, { exact: true, path: "/poll/:id", component: Poll }),
                    React.createElement(Route, { exact: true, path: "/proposals", component: ProposalsList }),
                    React.createElement(Route, { exact: true, path: "/404", component: NotFound }),
                    React.createElement(Redirect, { to: "/404", from: "*" })),
                this.elModal)));
    };
    return Routes;
}(Component));
export default (function () { return (React.createElement(Router, { history: history },
    React.createElement(Suspense, { fallback: React.createElement(Loader, null) },
        React.createElement(Switch, null,
            React.createElement(Route, { component: Routes }))))); });
