var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import React from 'react';
import Base from './base';
var Eye = /** @class */ (function (_super) {
    __extends(Eye, _super);
    function Eye() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Eye.prototype.render = function () {
        var _a = this.props, isCrossed = _a.isCrossed, props = __rest(_a, ["isCrossed"]);
        return (React.createElement("svg", __assign({}, props), this.renderChildren(isCrossed)));
    };
    Eye.prototype.renderChildren = function (isCrossed) {
        if (isCrossed) {
            return (React.createElement("path", { d: "M17.8,7.5l2.6-2.6l-1.3-1.3l-3,3C14.9,6,13.6,5.6,12,5.6C5.7,5.6,2,11.8,2,11.8s1.6,2.6,4.5,4.5l-2.8,2.8l1.3,1.3l3.2-3.2 C9.3,17.6,10.6,18,12,18c6.2,0,9.9-6.2,9.9-6.2S20.5,9.3,17.8,7.5z M7.6,11.8c0-2.4,1.9-4.3,4.4-4.3c0.9,0,1.8,0.3,2.5,0.8l-1.8,1.8 c0-0.2,0.1-0.4,0.3-0.6c-0.3-0.1-0.7-0.2-1-0.2v0h0c-1.4,0-2.5,1.1-2.5,2.5c0,0.4,0.1,0.8,0.3,1.1l-1.4,1.4 C7.9,13.6,7.6,12.7,7.6,11.8z M12,16.1c-0.8,0-1.5-0.2-2.2-0.6l1.4-1.4c0.3,0.1,0.5,0.1,0.8,0.1c1.4,0,2.5-1.1,2.5-2.5 c0-0.3-0.1-0.6-0.1-0.8l1.4-1.4c0.4,0.6,0.6,1.4,0.6,2.2C16.3,14.2,14.4,16.1,12,16.1z" }));
        }
        return (React.createElement("path", { d: "M12,9.5c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5c1.4,0,2.5-1.1,2.5-2.5c0-0.4-0.1-0.7-0.2-1c-0.2,0.2-0.4,0.4-0.7,0.4 c-0.5,0-0.9-0.4-0.9-0.9c0-0.3,0.1-0.5,0.4-0.7C12.7,9.6,12.3,9.5,12,9.5L12,9.5L12,9.5z M12,7.7c2.4,0,4.4,1.9,4.4,4.3 s-1.9,4.3-4.4,4.3c-2.4,0-4.4-1.9-4.4-4.3S9.6,7.7,12,7.7z M12.1,5.8C5.7,5.8,2,12,2,12s3.7,6.2,10.1,6.2c6.2,0,9.9-6.2,9.9-6.2 S18.2,5.8,12.1,5.8z" }));
    };
    return Eye;
}(Base));
export default Eye;
