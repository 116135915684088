var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { handleActions } from 'redux-actions-helpers';
import { set } from './actions';
var MAX_MOBILE = 670;
var MAX_TABLET = 860;
var initialState = {
    mobile: false,
    tablet: false,
    isRetina: false,
    desktop: true
};
export var key = 'deviceInfo';
export default handleActions((_a = {},
    _a[set] = function (state, action) {
        var result = __assign({}, state);
        if (action.width) {
            result.mobile = action.width <= MAX_MOBILE;
            result.tablet = action.width > MAX_MOBILE && action.width <= MAX_TABLET;
            result.desktop = action.width > MAX_TABLET;
        }
        if (action.pixelRatio) {
            result.isRetina = action.pixelRatio >= 2;
        }
        return result;
    },
    _a), { initialState: initialState });
