var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { PureComponent } from 'react';
import * as classnames from 'classnames/bind';
import { Link } from 'react-router-dom';
import AvatarIcon from 'component/icon/avatar';
import style from './style.pcss';
var Avatar = /** @class */ (function (_super) {
    __extends(Avatar, _super);
    function Avatar() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cn = classnames.bind(style);
        _this.onClick = function (e) {
            if (_this.props.onClick && e) {
                _this.props.onClick(e);
            }
        };
        return _this;
    }
    Object.defineProperty(Avatar.prototype, "renderPhoto", {
        get: function () {
            if (this.props.src) {
                return React.createElement("img", { src: this.props.src, className: this.cn('avatar__user') });
            }
            return React.createElement(AvatarIcon, { className: this.cn('avatar__placeholder') });
        },
        enumerable: true,
        configurable: true
    });
    Avatar.prototype.render = function () {
        var props = {
            onClick: this.onClick,
            className: this.cn('avatar', {
                'avatar_interactive': !!this.props.onClick
            }, this.props.className)
        };
        if (this.props.to) {
            return React.createElement(Link, __assign({ to: this.props.to }, props), this.renderPhoto);
        }
        return React.createElement("div", __assign({}, props), this.renderPhoto);
    };
    return Avatar;
}(PureComponent));
export default Avatar;
