import Content from './content';
import Sidebar from './sidebar';
import Main from './main';
import Box from './box';
import BoxHeader from './box-header';
export default {
    Content: Content,
    Sidebar: Sidebar,
    Main: Main,
    Box: Box,
    BoxHeader: BoxHeader
};
