import i18n from 'component/core/i18n';
var map = {
    '/en/': {
        title: i18n.t('components.header.navigation.polls'),
        to: '/',
        exact: true
    },
    '/en/proposals': {
        title: i18n.t('components.header.navigation.proposals'),
        to: '/proposals',
        exact: true
    },
    '/en/progress': {
        title: i18n.t('components.header.navigation.progress'),
        to: '/progress',
        exact: true
    },
    '/en/public-progress': {
        title: i18n.t('components.header.navigation.public-results'),
        to: '/public-progress',
        exact: true
    }
};
export default map;
