exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".avatar__3LBBa {\n        min-width: 40px;\n        width: 40px;\n        height: 40px;\n        border-radius: 50%;\n        overflow: hidden;\n        display: flex;\n    }\n\n        .avatar_interactive__3NpWO { cursor: pointer; }\n\n        .avatar__placeholder__17Tq6, .avatar__user__2LSXq {\n            width: 100%;\n            height: 100%;\n            object-fit: cover;\n        }\n\n        .avatar__user__2LSXq {\n            border-radius: 50%;\n        }\n", "",{"version":3,"sources":["style.pcss"],"names":[],"mappings":"AAEI;QACI,eAAe;QACf,WAAW;QACX,YAAY;QACZ,kBAAkB;QAClB,gBAAgB;QAChB,aAAa;IAcjB;;QAZI,6BAAgB,eAAe,EAAE;;QAEjC;YAEI,WAAW;YACX,YAAY;YACZ,iBAAiB;QACrB;;QAEA;YACI,kBAAkB;QACtB","file":"style.pcss","sourcesContent":[":local {\n\n    .avatar {\n        min-width: 40px;\n        width: 40px;\n        height: 40px;\n        border-radius: 50%;\n        overflow: hidden;\n        display: flex;\n\n        &_interactive { cursor: pointer; }\n\n        &__placeholder,\n        &__user {\n            width: 100%;\n            height: 100%;\n            object-fit: cover;\n        }\n\n        &__user {\n            border-radius: 50%;\n        }\n    }\n}\n"]}]);

// Exports
exports.locals = {
	"avatar": "avatar__3LBBa",
	"avatar_interactive": "avatar_interactive__3NpWO",
	"avatar__placeholder": "avatar__placeholder__17Tq6",
	"avatar__user": "avatar__user__2LSXq"
};