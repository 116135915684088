var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import * as classnames from 'classnames/bind';
import axios from 'axios';
import i18n from 'component/core/i18n';
import Loader from 'component/loader';
import UI from 'component/ui';
import { getPublicDetail } from 'component/api/polls';
import UserAnswer from './user-answers';
import { key as keyUser } from 'component/user/reducer';
import style from './style.pcss';
var PublicProgressDetail = /** @class */ (function (_super) {
    __extends(PublicProgressDetail, _super);
    function PublicProgressDetail() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cn = classnames.bind(style);
        _this.cancelTokenSource = {
            cancel: axios.CancelToken.source()
        };
        _this.state = {
            list: [],
            pending: false,
            error: null,
            total: 0,
            pk: _this.props.userId
        };
        _this.getPublicDetail = function () {
            var params = {
                poll: _this.props.match.params.id,
                page: 1,
                per_page: 9999
            };
            _this.setState({ pending: true }, function () {
                _this.cancelTokenSource.cancel.cancel('Request `getProgressInfo` abort.');
                _this.cancelTokenSource.cancel = axios.CancelToken.source();
                getPublicDetail({
                    params: params,
                    cancelToken: _this.cancelTokenSource.cancel.token
                })
                    .then(function (_a) {
                    var results = _a.results, count = _a.count, total = _a.total;
                    _this.setState({
                        pending: false,
                        total: count,
                        list: __spread(_this.state.list, results)
                    });
                })
                    .catch(function (error) {
                    if (!axios.isCancel(error)) {
                        console.error(error);
                        _this.setState({
                            error: error.error,
                            pending: false
                        });
                    }
                });
            });
        };
        return _this;
    }
    PublicProgressDetail.prototype.componentDidMount = function () {
        this.getPublicDetail();
    };
    PublicProgressDetail.prototype.componentDidUpdate = function (props) {
        var isProgressIdChanged = props.match.params.id !== this.props.match.params.id;
        if (isProgressIdChanged) {
            this.getPublicDetail();
        }
    };
    PublicProgressDetail.prototype.componentWillUnmount = function () {
        this.cancelTokenSource.cancel.cancel('Component is unmount! Request `getPublicDetail` abort.');
    };
    Object.defineProperty(PublicProgressDetail.prototype, "elResults", {
        get: function () {
            var _this = this;
            if (!this.state.list.length && this.state.pending) {
                return React.createElement(Loader, null);
            }
            if (this.state.error) {
                return React.createElement("span", { className: this.cn('results__error') }, this.state.error);
            }
            if (!this.state.list.length) {
                return React.createElement("span", { className: this.cn('results__empty') }, i18n.t('route.progress.empty'));
            }
            return (React.createElement("div", { className: this.cn('results__list') }, this.state.list.map(function (item) { return (React.createElement(UserAnswer, { className: _this.cn('results__container'), key: item.user.pk, list: item.data }, item.user.fullname || item.user.email)); })));
        },
        enumerable: true,
        configurable: true
    });
    PublicProgressDetail.prototype.render = function () {
        if (!this.props.userId) {
            return React.createElement(Redirect, { to: "/login" });
        }
        if (this.props.userId && !this.props.isStaff || !this.state.pending && this.state.error && !this.state.list.length) {
            return React.createElement(Redirect, { to: "/404" });
        }
        return (React.createElement(UI.Main, { className: this.cn('results') },
            React.createElement(Helmet, { title: i18n.t('helmet.title.progress-results') }),
            this.elResults));
    };
    return PublicProgressDetail;
}(Component));
export default connect(function (store) { return ({
    userId: store[keyUser].pk,
    isStaff: store[keyUser].is_staff
}); })(PublicProgressDetail);
