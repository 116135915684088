var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Base from './base';
var Timer = /** @class */ (function (_super) {
    __extends(Timer, _super);
    function Timer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Timer.prototype.render = function () {
        return (React.createElement("svg", __assign({}, this.props),
            React.createElement("path", { d: "M9.075 4.125l.775-.775c.2-.2.2-.5 0-.7-.2-.2-.5-.2-.7 0l-.725.725A5.12 5.12 0 0 0 5.5 2.025V1h1c.275 0 .5-.225.5-.5S6.775 0 6.5 0h-3c-.275 0-.5.225-.5.5s.225.5.5.5h1v1.025c-1.1.1-2.125.575-2.925 1.35L.85 2.65a.507.507 0 0 0-.7.05.526.526 0 0 0 0 .65l.775.775c-1.6 2.25-1.05 5.375 1.2 6.975C4.375 12.7 7.5 12.15 9.1 9.9a5.04 5.04 0 0 0-.025-5.775zM5 11c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4zm2.5-4c0 .275-.225.5-.5.5H5a.501.501 0 0 1-.5-.5V4.5c0-.275.225-.5.5-.5s.5.225.5.5v2H7c.275 0 .5.225.5.5z" })));
    };
    return Timer;
}(Base));
export default Timer;
