var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Fragment, PureComponent } from 'react';
import * as classnames from 'classnames/bind';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import axios from 'axios';
import i18n from 'component/core/i18n';
import IconLogoText from 'component/icon/logo-text';
import { key as keyUser } from 'component/user/reducer';
import Button from 'component/button';
import { reset } from 'component/user/actions';
import IconLogout from 'component/icon/logout';
import IconLoader from 'component/icon/loader';
import { logout } from 'component/api/user';
import Navigation from './../navigation';
import styleSubNavigation from './sub-navigation.pcss';
import style from './index.pcss';
var Tablet = /** @class */ (function (_super) {
    __extends(Tablet, _super);
    function Tablet() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cn = classnames.bind(style);
        _this.cancelTokenSource = axios.CancelToken.source();
        _this.state = {
            pending: false
        };
        _this.onClickLogout = function (e) {
            e && e.preventDefault();
            if (!_this.state.pending) {
                _this.setState({
                    pending: true
                }, function () {
                    logout({
                        cancelToken: _this.cancelTokenSource.token
                    })
                        .then(function () {
                        _this.props.dispatch(reset());
                    })
                        .catch(function (error) {
                        if (!axios.isCancel(error)) {
                            console.error(error);
                        }
                    });
                });
            }
        };
        return _this;
    }
    Tablet.prototype.componentWillUnmount = function () {
        this.cancelTokenSource.cancel('Component is unmount! Request `logout` abort.');
    };
    Object.defineProperty(Tablet.prototype, "userName", {
        get: function () {
            if (this.props.profile_name) {
                return this.props.profile_name;
            }
            if (this.props.first_name && this.props.last_name) {
                return this.props.first_name + " " + this.props.last_name;
            }
            if (this.props.first_name && !this.props.last_name) {
                return this.props.first_name;
            }
            if (!this.props.first_name && this.props.last_name) {
                return this.props.last_name;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Tablet.prototype, "elButtonLogout", {
        get: function () {
            if (this.props.isAuth) {
                if (this.state.pending) {
                    return (React.createElement(Button, { isYellow: false, className: this.cn('header__button-logout') },
                        React.createElement("span", null, i18n.t('components.user.menu.buttons.logout')),
                        React.createElement(IconLoader, { width: 24, height: 24, className: this.cn('menu__icon') })));
                }
                return (React.createElement(Button, { isYellow: true, onClick: this.onClickLogout, className: this.cn('header__button-logout') },
                    i18n.t('components.user.menu.buttons.logout'),
                    React.createElement(IconLogout, { width: 24, height: 24, className: this.cn('menu__icon') })));
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Tablet.prototype, "elUser", {
        get: function () {
            if (this.props.isAuth) {
                return (React.createElement(Fragment, null,
                    this.userName,
                    this.elButtonLogout));
            }
            return (React.createElement(Button, { isYellow: true, 
                // tslint:disable-next-line max-line-length
                href: "https://asgardia.space/o/authorize/?response_type=code&client_id=tHqGekigBSRcQAfEBcyBHNafxu2sc7bpyVhYSjJm&redirect_uri=https://vote-parliament.asgardia.space/api/asg-oauth2/", className: this.cn('header__button-login') }, i18n.t('components.header.buttons.login')));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Tablet.prototype, "elBar", {
        get: function () {
            return (React.createElement(Fragment, null, this.elUser));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Tablet.prototype, "elSubNavigation", {
        get: function () {
            if (this.props.location) {
                var routes = [
                    '/en/',
                    '/en/proposals',
                    '/en/public-progress'
                ];
                if (this.props.isStaff) {
                    routes.push('/en/progress');
                }
                return (React.createElement("div", { className: this.cn('header__sub-navigation') },
                    React.createElement(Navigation, { isChild: false, className: styleSubNavigation, children: routes })));
            }
        },
        enumerable: true,
        configurable: true
    });
    Tablet.prototype.render = function () {
        return (React.createElement("div", { className: this.cn('header') },
            React.createElement("div", { className: this.cn('header__panel') },
                React.createElement("a", { href: "/", className: this.cn('header__logo') },
                    React.createElement(IconLogoText, { height: 44, width: 187, className: this.cn('header__icon-logo') })),
                React.createElement("div", { className: this.cn('header__bar') }, this.elBar)),
            this.elSubNavigation));
    };
    return Tablet;
}(PureComponent));
export default compose(withRouter, connect(function (store) { return ({
    isAuth: !!store[keyUser].pk,
    profile_name: store[keyUser].profile_name,
    first_name: store[keyUser].first_name,
    last_name: store[keyUser].last_name,
    isStaff: store[keyUser].is_staff
}); }))(Tablet);
