var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Base from './base';
var Post = /** @class */ (function (_super) {
    __extends(Post, _super);
    function Post() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Post.prototype.render = function () {
        return (React.createElement("svg", __assign({}, this.props),
            React.createElement("path", { d: "M15.113 0c-.75 0-1.5.3-2.025.825L10.2 3.75H2.25A2.257 2.257 0 0 0 0 6v9.75A2.257 2.257 0 0 0 2.25 18H12a2.257 2.257 0 0 0 2.25-2.25V7.8l2.925-2.925a2.852 2.852 0 0 0 0-4.05A2.988 2.988 0 0 0 15.112 0zM12.75 15.75c0 .413-.338.75-.75.75H2.25a.752.752 0 0 1-.75-.75V6c0-.412.337-.75.75-.75H8.7L6.113 7.838c-1.5 1.5-2.363 3.525-2.326 5.662 0 .412.338.75.75.75a7.97 7.97 0 0 0 5.663-2.325l2.588-2.588-.038 6.413zm3.337-11.925l-7.012 7.013c-1.012 1.012-2.362 1.687-3.787 1.837.187-1.425.824-2.775 1.837-3.788l7.012-7.012c.563-.525 1.425-.45 1.95.075.488.563.488 1.35 0 1.875z" })));
    };
    return Post;
}(Base));
export default Post;
