var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import * as classnames from 'classnames/bind';
import axios from 'axios';
import i18n from 'component/core/i18n';
import Loader from 'component/loader';
import UI from 'component/ui';
import { getProgressDetail, getNotRegistered } from 'component/api/polls';
import { key as keyUser } from 'component/user/reducer';
import IconClose from 'component/icon/close';
import IconOk from 'component/icon/status-ok';
import style from './style.pcss';
var ProgressDetail = /** @class */ (function (_super) {
    __extends(ProgressDetail, _super);
    function ProgressDetail() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cn = classnames.bind(style);
        _this.cancelTokenSource = {
            cancelProgress: axios.CancelToken.source(),
            cancelNotRegistered: axios.CancelToken.source()
        };
        _this.state = {
            list: [],
            total: 0,
            pending: false,
            totalVotedInfo: null,
            error: null,
            pendingNotReg: false,
            totalNotReg: 0,
            listNotReg: [],
            errorNotReg: null
        };
        _this.getProgressInfo = function () {
            var params = {
                poll: _this.props.match.params.id,
                page: 1,
                per_page: 9999
            };
            _this.setState({ pending: true }, function () {
                _this.cancelTokenSource.cancelProgress.cancel('Request `getProgressInfo` abort.');
                _this.cancelTokenSource.cancelProgress = axios.CancelToken.source();
                getProgressDetail({
                    params: params,
                    cancelToken: _this.cancelTokenSource.cancelProgress.token
                })
                    .then(function (_a) {
                    var results = _a.results, count = _a.count, total = _a.total;
                    _this.setState({
                        pending: false,
                        total: count,
                        list: __spread(_this.state.list, results),
                        totalVotedInfo: total
                    });
                })
                    .catch(function (error) {
                    if (!axios.isCancel(error)) {
                        console.error(error);
                        _this.setState({
                            error: error.error,
                            pending: false
                        });
                    }
                });
            });
        };
        _this.getNotRegistered = function () {
            var params = {
                poll: _this.props.match.params.id,
                page: 1,
                per_page: 9999
            };
            _this.setState({ pendingNotReg: true }, function () {
                _this.cancelTokenSource.cancelNotRegistered.cancel('Request `getProgressInfo` abort.');
                _this.cancelTokenSource.cancelNotRegistered = axios.CancelToken.source();
                getNotRegistered({
                    params: params,
                    cancelToken: _this.cancelTokenSource.cancelNotRegistered.token
                })
                    .then(function (_a) {
                    var results = _a.results, count = _a.count, total = _a.total;
                    _this.setState({
                        pendingNotReg: false,
                        totalNotReg: count,
                        listNotReg: __spread(_this.state.listNotReg, results)
                    });
                })
                    .catch(function (error) {
                    if (!axios.isCancel(error)) {
                        console.error(error);
                        _this.setState({
                            errorNotReg: error.error,
                            pendingNotReg: false
                        });
                    }
                });
            });
        };
        _this.registered = function (registered) {
            if (registered) {
                return (React.createElement(IconOk, { className: _this.cn('results__icon-ok'), height: 14, width: 14, viewBox: "0 0 12 12" }));
            }
            else {
                return (React.createElement(IconClose, { className: _this.cn('results__icon-close'), height: 20, width: 20 }));
            }
        };
        return _this;
    }
    ProgressDetail.prototype.componentDidMount = function () {
        this.getProgressInfo();
        this.getNotRegistered();
    };
    ProgressDetail.prototype.componentDidUpdate = function (props) {
        var isProgressIdChanged = props.match.params.id !== this.props.match.params.id;
        if (isProgressIdChanged) {
            this.getProgressInfo();
            this.getNotRegistered();
        }
    };
    ProgressDetail.prototype.componentWillUnmount = function () {
        this.cancelTokenSource.cancelProgress.cancel('Component is unmount! Request `getProgressInfo` abort.');
        this.cancelTokenSource.cancelNotRegistered.cancel('Component is unmount! Request `getNotRegistered` abort.');
    };
    Object.defineProperty(ProgressDetail.prototype, "elTotals", {
        get: function () {
            if (this.state.totalVotedInfo && !this.state.pending) {
                return (React.createElement("div", { className: this.cn('results__totals') },
                    React.createElement("div", { className: this.cn('results__totals-item') },
                        React.createElement("span", null, i18n.t('route.progress.total-voted-info.registered')),
                        this.state.totalVotedInfo.total_reg),
                    React.createElement("div", { className: this.cn('results__totals-item') },
                        React.createElement("span", null, i18n.t('route.progress.total-voted-info.all-questions')),
                        this.state.totalVotedInfo.total_full_voted),
                    React.createElement("div", { className: this.cn('results__totals-item') },
                        React.createElement("span", null, i18n.t('route.progress.total-voted-info.part-questions')),
                        this.state.totalVotedInfo.total_part_voted),
                    React.createElement("div", { className: this.cn('results__totals-item') },
                        React.createElement("span", null, i18n.t('route.progress.total-voted-info.no-questions')),
                        this.state.totalVotedInfo.total_not_voted)));
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressDetail.prototype, "elVoteName", {
        get: function () {
            if (this.state.totalVotedInfo && !this.state.pending) {
                return (this.state.totalVotedInfo.vote_name);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressDetail.prototype, "elResultsList", {
        get: function () {
            var _this = this;
            if (!this.state.list.length && this.state.pending) {
                return React.createElement(Loader, null);
            }
            if (this.state.error) {
                return React.createElement("span", { className: this.cn('results__error') }, this.state.error);
            }
            if (!this.state.list.length) {
                return React.createElement("span", { className: this.cn('results__empty') }, i18n.t('route.progress.empty'));
            }
            return (React.createElement("div", { className: this.cn('results__list') },
                React.createElement("div", { className: this.cn('results__header') },
                    React.createElement("div", { className: this.cn('results__item-sub') },
                        React.createElement("div", { className: this.cn('results__item-number') }),
                        React.createElement("div", { className: this.cn('results__header-item-big') }, i18n.t('route.progress.user'))),
                    React.createElement("div", { className: this.cn('results__header-item') }, i18n.t('route.progress.registered')),
                    React.createElement("div", { className: this.cn('results__header-item') }, i18n.t('route.progress.total')),
                    React.createElement("div", { className: this.cn('results__header-item') }, i18n.t('route.progress.voted'))),
                this.state.list.map(function (item) { return (React.createElement("div", { key: item.id, className: _this.cn('results__item') },
                    React.createElement("div", { className: _this.cn('results__item-sub') },
                        React.createElement("div", { className: _this.cn('results__item-number') }, item.id),
                        React.createElement("div", { className: _this.cn('results__item-el-big') }, item.user.fullname || item.user.email)),
                    React.createElement("div", { className: _this.cn('results__item-el') }, _this.registered(item.registered.status)),
                    React.createElement("div", { className: _this.cn('results__item-el') }, item.total_questions),
                    React.createElement("div", { className: _this.cn('results__item-el') }, item.voted_questions))); })));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressDetail.prototype, "elNotRegList", {
        get: function () {
            var _this = this;
            if (!this.state.listNotReg.length && this.state.pendingNotReg) {
                return React.createElement(Loader, null);
            }
            if (this.state.errorNotReg) {
                return React.createElement("span", { className: this.cn('results__error') }, this.state.errorNotReg);
            }
            if (!this.state.listNotReg.length) {
                return React.createElement("span", { className: this.cn('results__empty') }, i18n.t('route.progress.empty'));
            }
            return (React.createElement("div", { className: this.cn('results__list') },
                React.createElement("div", { className: this.cn('results__header') },
                    React.createElement("div", { className: this.cn('results__item-sub') },
                        React.createElement("div", { className: this.cn('results__item-number') }),
                        React.createElement("div", { className: this.cn('results__header-item-big') }, i18n.t('route.progress.user')))),
                this.state.listNotReg.map(function (item) { return (React.createElement("div", { key: item.id, className: _this.cn('results__item') },
                    React.createElement("div", { className: _this.cn('results__item-sub') },
                        React.createElement("div", { className: _this.cn('results__item-number') }, item.id),
                        React.createElement("div", { className: _this.cn('results__item-el-big') }, item.user.fullname || item.user.email)))); })));
        },
        enumerable: true,
        configurable: true
    });
    ProgressDetail.prototype.render = function () {
        if (!this.props.userId) {
            return React.createElement(Redirect, { to: "/login" });
        }
        if (this.props.userId && !this.props.isStaff || !this.state.pending && this.state.error && !this.state.list.length) {
            return React.createElement(Redirect, { to: "/404" });
        }
        return (React.createElement(UI.Main, { className: this.cn('results') },
            React.createElement(Helmet, { title: i18n.t('helmet.title.progress-results') }),
            React.createElement(UI.Box, { className: this.cn('results__container'), padding: true },
                React.createElement(UI.BoxHeader, { className: this.cn('results__title') },
                    i18n.t('route.progress.results'),
                    " ",
                    this.elVoteName),
                this.elTotals,
                this.elResultsList),
            React.createElement(UI.Box, { className: this.cn('results__container'), padding: true },
                React.createElement(UI.BoxHeader, { className: this.cn('results__title') },
                    i18n.t('route.progress.not-registered'),
                    " ",
                    this.elVoteName),
                this.elNotRegList)));
    };
    return ProgressDetail;
}(Component));
export default connect(function (store) { return ({
    userId: store[keyUser].pk,
    isStaff: store[keyUser].is_staff
}); })(ProgressDetail);
