var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Base from './base';
var NotVoted = /** @class */ (function (_super) {
    __extends(NotVoted, _super);
    function NotVoted() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NotVoted.prototype.render = function () {
        return (React.createElement("svg", __assign({}, this.props),
            React.createElement("path", { d: "M9.273 12C10.773 12 12 10.773 12 9.273V2.727C12 1.227 10.773 0 9.273 0H2.727A2.735 2.735 0 0 0 0 2.727v6.546C0 10.773 1.227 12 2.727 12h6.546zM1.09 9.273V2.727c0-.9.736-1.636 1.636-1.636h6.546c.9 0 1.636.736 1.636 1.636v6.546c0 .9-.736 1.636-1.636 1.636H2.727c-.9 0-1.636-.736-1.636-1.636z" })));
    };
    return NotVoted;
}(Base));
export default NotVoted;
