var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { PureComponent } from 'react';
import * as classnames from 'classnames/bind';
import { Link } from 'react-router-dom';
import Loader from 'component/loader';
import style from './style.pcss';
var Button = /** @class */ (function (_super) {
    __extends(Button, _super);
    function Button() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cn = classnames.bind(style);
        _this.onClick = function (e) {
            if (_this.props.onClick) {
                _this.props.onClick(e);
            }
        };
        return _this;
    }
    Object.defineProperty(Button.prototype, "elChildren", {
        get: function () {
            if (this.props.isLoading) {
                return (React.createElement("div", { className: this.cn('button__loader') },
                    React.createElement(Loader, { className: this.cn('button__loader-icon') })));
            }
            return this.props.children;
        },
        enumerable: true,
        configurable: true
    });
    Button.prototype.render = function () {
        var props = {
            className: this.cn('button', {
                'button_secondary': this.props.isSecondary,
                'button_yellow': this.props.isYellow,
                'button_small': this.props.isSmall
            }, this.props.className),
            children: this.elChildren
        };
        if (this.props.href) {
            return React.createElement("a", __assign({ href: this.props.href, target: this.props.target }, props));
        }
        else if (this.props.to) {
            return React.createElement(Link, __assign({ to: this.props.to, target: this.props.target }, props));
        }
        else {
            return (React.createElement("button", __assign({ type: this.props.type, disabled: this.props.disabled, onClick: this.onClick }, props)));
        }
    };
    Button.defaultProps = {
        disabled: false,
        isLoading: false,
        type: 'button'
    };
    return Button;
}(PureComponent));
export default Button;
