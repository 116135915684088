var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import * as classnames from 'classnames/bind';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import Loader from 'component/loader';
import cancelToken from 'component/core/cancel-token';
import UI from 'component/ui';
import Button from 'component/button';
import { getPoll, pollRegister } from 'component/api/polls';
import { key as keyDeviceInfo } from 'component/device-info/reducer';
import { key as keyUser } from 'component/user/reducer';
import i18n from 'component/core/i18n';
import { Trans } from 'react-i18next';
import Question from './question';
import ProgressBar from './progress-bar';
import style from './index.pcss';
var Poll = /** @class */ (function (_super) {
    __extends(Poll, _super);
    function Poll() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cn = classnames.bind(style);
        _this.cancelToken = cancelToken.create();
        _this.state = {
            poll: null,
            registered: false,
            pending: false,
            error: null,
            answeredList: {}
        };
        _this._request = function () {
            _this.setState({
                pending: true
            }, function () {
                getPoll(_this.props.match.params.id, { cancelToken: _this.cancelToken.new() })
                    .then(function (poll) {
                    _this.setState({
                        poll: poll,
                        answeredList: _this.getAnsweredList(poll.questions),
                        pending: false,
                        error: null,
                        registered: poll.registered
                    });
                })
                    .catch(function (error) {
                    if (!axios.isCancel(error)) {
                        console.error(error);
                        _this.setState({
                            error: error.error,
                            pending: false
                        });
                    }
                });
            });
        };
        _this._requestServer = function () {
            _this.setState({
                pending: true
            }, function () {
                if (_this.state.poll) {
                    pollRegister({
                        cancelToken: _this.cancelToken.new(),
                        data: {
                            poll: _this.state.poll.id,
                            owner: _this.props.userId,
                            server: 1
                        }
                    })
                        .then(function () {
                        _this.setState({
                            registered: true,
                            pending: false,
                            error: null
                        });
                    })
                        .catch(function (error) {
                        if (!axios.isCancel(error)) {
                            console.error(error);
                            _this.setState({
                                error: error.error,
                                pending: false
                            });
                        }
                    });
                }
            });
        };
        _this.getAnsweredList = function (questions) {
            var answered = {};
            questions.forEach(function (question) {
                var answer = question.variants.some(function (variant) { return variant.checked; });
                answered[question.pk] = answer;
            });
            return answered;
        };
        _this.onChangeAnswer = function (questionId) {
            var _a;
            _this.setState({
                answeredList: __assign({}, _this.state.answeredList, (_a = {}, _a[questionId] = true, _a))
            });
        };
        return _this;
    }
    Poll.prototype.componentDidMount = function () {
        this._request();
    };
    Poll.prototype.componentWillUnmount = function () {
        this.cancelToken.remove();
    };
    Object.defineProperty(Poll.prototype, "elHelmet", {
        get: function () {
            if (this.state.poll) {
                return React.createElement(Helmet, { title: this.state.poll.name });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Poll.prototype, "elProgress", {
        get: function () {
            var _this = this;
            if (this.state.poll) {
                var votedList = Object.keys(this.state.answeredList).filter(function (id) { return _this.state.answeredList[Number(id)]; });
                var count = votedList.length;
                return (React.createElement("div", { className: this.cn('poll__progress') },
                    React.createElement(ProgressBar, { answeredList: this.state.answeredList }),
                    React.createElement("div", { className: this.cn('poll__progress-stats') },
                        React.createElement("div", { className: this.cn('poll__progress-stat') },
                            React.createElement(Trans, { i18n: i18n, i18nKey: "route.polls.voted", count: count },
                                "Voted: ",
                                React.createElement("span", { className: this.cn('content-box__in') }, { count: count }))),
                        React.createElement("div", { className: this.cn('poll__progress-stat') },
                            React.createElement(Trans, { i18n: i18n, i18nKey: "route.polls.voted", count: this.state.poll.questions.length },
                                "Total: ",
                                React.createElement("span", { className: this.cn('content-box__in') }, { count: count }))))));
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Poll.prototype, "elPollRegistration", {
        get: function () {
            return (React.createElement(UI.Box, { className: this.cn('poll__register'), padding: true },
                React.createElement("strong", { className: this.cn('poll__register-header') }, i18n.t('route.polls.server')),
                React.createElement("p", { className: this.cn('poll__register-text') }, i18n.t('route.polls.register')),
                React.createElement(Button, { onClick: this._requestServer, disabled: this.state.pending }, this.state.pending ? React.createElement(Loader, null) : 'Register')));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Poll.prototype, "elQuestions", {
        get: function () {
            var _this = this;
            if (this.state.poll && Array.isArray(this.state.poll.questions)) {
                return (React.createElement(UI.Box, { className: this.cn('poll__content'), padding: true },
                    React.createElement(UI.BoxHeader, { className: this.cn('poll__header') }, this.state.poll.name),
                    this.elProgress,
                    this.state.poll.questions.map(function (question, index) {
                        return React.createElement(Question, __assign({ onChangeAnswer: _this.onChangeAnswer, index: index + 1, key: question.pk }, question));
                    })));
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Poll.prototype, "elFinished", {
        get: function () {
            return (React.createElement(UI.Box, { className: this.cn('poll__content'), padding: true },
                React.createElement(UI.BoxHeader, null, i18n.t('route.polls.finished'))));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Poll.prototype, "elNotStarted", {
        get: function () {
            return (React.createElement(UI.Box, { className: this.cn('poll__content'), padding: true },
                React.createElement(UI.BoxHeader, null, i18n.t('route.polls.not-started'))));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Poll.prototype, "elNoQuestions", {
        get: function () {
            return (React.createElement(UI.Box, { className: this.cn('poll__content'), padding: true },
                React.createElement(UI.BoxHeader, null, i18n.t('route.polls.no-questions'))));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Poll.prototype, "elContent", {
        get: function () {
            if (this.state.poll && this.state.poll.status === 'Finished') {
                return this.elFinished;
            }
            if (this.state.poll && this.state.poll.status === 'Not started') {
                return this.elNotStarted;
            }
            if (this.state.poll && !this.state.poll.questions.length) {
                return this.elNoQuestions;
            }
            if (this.state.poll && this.state.poll.registration && !this.state.registered) {
                return this.elPollRegistration;
            }
            return this.elQuestions;
        },
        enumerable: true,
        configurable: true
    });
    Poll.prototype.render = function () {
        if (!this.props.userId) {
            return React.createElement(Redirect, { to: "/login" });
        }
        if (!this.state.poll && this.state.pending) {
            return React.createElement(Loader, null);
        }
        if (!this.state.pending && this.state.error && !this.state.poll) {
            return React.createElement(Redirect, { to: "/404" });
        }
        if (this.state.error) {
            return React.createElement("span", { className: this.cn('poll__error') }, this.state.error);
        }
        return (React.createElement(UI.Main, { className: this.cn('poll') },
            this.elHelmet,
            this.elContent));
    };
    return Poll;
}(Component));
export default connect(function (store) { return ({
    isMobile: store[keyDeviceInfo].mobile,
    userId: store[keyUser].pk
}); })(Poll);
