var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { applyMiddleware, createStore } from 'redux';
import reducers from 'src/reducers';
import config from 'config';
var middleware = [];
if (__DEVELOPMENT__) {
    // tslint:disable-next-line
    var createLogger = require('redux-logger').createLogger;
    middleware.push(createLogger(config['redux-logger'] || {}));
}
var preloadedState = {};
// @TODO: Подумать над нормальным решением с авторизацией
var user = sessionStorage.getItem('user');
if (user) {
    try {
        preloadedState.user = JSON.parse(user);
    }
    catch (error) {
        console.error(error);
    }
}
var store = createStore(reducers, preloadedState, applyMiddleware.apply(void 0, __spread(middleware)));
if (__DEVELOPMENT__ && module.hot) {
    module.hot.accept('src/reducers', function () {
        store.replaceReducer(reducers);
    });
}
export default store;
