exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".progress-bar__QFqsl {\n        display: flex;\n        max-width: 400px;\n        width: 100%;\n        height: 10px;\n        border-radius: 2px;\n        overflow: hidden;\n    }\n\n        .progress-bar__peace__27DkV {\n            flex-grow: 1;\n        }\n\n        .progress-bar__peace_skipped__ToCKo {\n                background: #f7b322;\n            }\n\n        .progress-bar__peace_success__2-d-R {\n                background: #27ae60;\n            }\n", "",{"version":3,"sources":["progress-bar.pcss"],"names":[],"mappings":"AAEI;QACI,aAAa;QACb,gBAAgB;QAChB,WAAW;QACX,YAAY;QACZ,kBAAkB;QAClB,gBAAgB;IAapB;;QAXI;YACI,YAAY;QAShB;;QAPI;gBACI,mBAAyB;YAC7B;;QAEA;gBACI,mBAAgC;YACpC","file":"progress-bar.pcss","sourcesContent":[":local {\n\n    .progress-bar {\n        display: flex;\n        max-width: 400px;\n        width: 100%;\n        height: 10px;\n        border-radius: 2px;\n        overflow: hidden;\n\n        &__peace {\n            flex-grow: 1;\n\n            &_skipped {\n                background: var(--yellow);\n            }\n\n            &_success {\n                background: var(--primary-green);\n            }\n        }\n    }\n}\n"]}]);

// Exports
exports.locals = {
	"progress-bar": "progress-bar__QFqsl",
	"progress-bar__peace": "progress-bar__peace__27DkV",
	"progress-bar__peace_skipped": "progress-bar__peace_skipped__ToCKo",
	"progress-bar__peace_success": "progress-bar__peace_success__2-d-R"
};