import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// @TODO: Dynamic load translate
import en from 'locale/en/index.json';
i18n
    .use(initReactI18next)
    .init({
    debug: __DEVELOPMENT__,
    lng: 'en',
    fallbackLng: __DEVELOPMENT__ ? false : 'en',
    interpolation: { escapeValue: false },
    keySeparator: '.',
    resources: {
        // @TODO: Dynamic load translate
        en: { translation: en }
    }
    // @TODO: Dynamic load translate
    // react-i18next options
    // react: {
    //     wait: true
    // }
});
export default i18n;
