var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as classnames from 'classnames/bind';
import Avatar from 'component/avatar';
import style from './avatar.pcss';
var UserAvatar = /** @class */ (function (_super) {
    __extends(UserAvatar, _super);
    function UserAvatar() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cn = classnames.bind(style);
        _this.onClick = function (e) {
            e && e.preventDefault();
            _this.props.onClick && _this.props.onClick(e);
        };
        return _this;
    }
    UserAvatar.prototype.render = function () {
        return (React.createElement("div", { onClick: this.onClick, className: this.cn('user-avatar', {
                'user-avatar_interactive': !!this.props.onClick
            }, this.props.className), "data-count": 0 },
            React.createElement(Avatar, { src: this.props.photo, className: this.cn('user-avatar__avatar') })));
    };
    UserAvatar.defaultProps = {
        size: 50
    };
    return UserAvatar;
}(PureComponent));
export default connect(function () { return ({
    photo: null
}); })(UserAvatar);
