var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as classnames from 'classnames/bind';
import { Helmet } from 'react-helmet';
import { key as keyUser } from 'component/user/reducer';
import i18n from 'component/core/i18n';
import Button from 'component/button';
import UI from 'component/ui';
import style from './style.pcss';
var Login = /** @class */ (function (_super) {
    __extends(Login, _super);
    function Login() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cn = classnames.bind(style);
        return _this;
    }
    Login.prototype.render = function () {
        if (this.props.isAuth) {
            return React.createElement(Redirect, { to: "/" });
        }
        return (React.createElement(UI.Main, { className: this.cn('login__ui-main') },
            React.createElement(Helmet, { title: i18n.t('helmet.title.login'), meta: [{
                        name: 'document-state',
                        content: 'static'
                    }] }),
            React.createElement(UI.Box, { padding: true, className: this.cn('login__ui-box') },
                React.createElement("div", { className: this.cn('login__content') },
                    React.createElement(Button, { type: "submit", isYellow: true, 
                        // tslint:disable-next-line max-line-length
                        href: "https://asgardia.space/o/authorize/?response_type=code&client_id=tHqGekigBSRcQAfEBcyBHNafxu2sc7bpyVhYSjJm&redirect_uri=https://vote-parliament.asgardia.space/api/asg-oauth2/", className: this.cn('login__button-submit') }, i18n.t('route.login.via-asgardia'))))));
    };
    return Login;
}(PureComponent));
export default connect(function (store) { return ({
    isAuth: !!store[keyUser].pk
}); })(Login);
