var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component, createRef } from 'react';
import * as classnames from 'classnames/bind';
import EyeIcon from 'src/components/icon/eye';
import CloseIcon from 'src/components/icon/close';
import LocationIcon from 'src/components/icon/location';
import LoaderIcon from 'component/icon/loader';
import SearchIcon from 'src/components/icon/search';
import style from './style.pcss';
var Input = /** @class */ (function (_super) {
    __extends(Input, _super);
    function Input() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.$input = createRef();
        _this.state = {
            type: _this.props.type,
            showPassword: false,
            inputWidth: 0,
            value: ''
        };
        _this.cn = classnames.bind(style);
        _this.getInputWidth = function () {
            var inputWidth = _this.$input && _this.$input.current && _this.$input.current.offsetWidth || 0;
            _this.setState({ inputWidth: inputWidth }, function () {
                if (_this.props.getInputWidth) {
                    _this.props.getInputWidth(_this.state.inputWidth);
                }
            });
        };
        _this.onChange = function (e) {
            _this.setState({
                watch: false,
                value: e.target.value
            });
            if (e && _this.props.onChange) {
                _this.props.onChange(e.target.value, e);
            }
        };
        _this.onFocus = function (e) {
            _this.setState({ focus: true });
            if (e && _this.props.onFocus) {
                _this.props.onFocus(e);
            }
        };
        _this.onBlur = function (e) {
            _this.setState({
                focus: false,
                watch: true
            });
            if (e && _this.props.onBlur) {
                _this.props.onBlur(e);
            }
        };
        _this.onKeyDown = function (e) {
            if (e && _this.props.onKeyDown) {
                _this.props.onKeyDown(e);
            }
        };
        _this.onClickShowPassword = function () {
            _this.setState({ showPassword: !_this.state.showPassword });
        };
        _this.onClickClear = function (e) {
            if (e && _this.props.onClear) {
                _this.props.onClear(e);
            }
        };
        _this.elIconClear = function (type) {
            var props = {
                width: 16,
                height: 16,
                onClick: _this.onClickClear,
                className: _this.cn('input__icon', "input__icon_" + type)
            };
            var icons = {
                clear: React.createElement(CloseIcon, __assign({}, props)),
                loading: React.createElement(LoaderIcon, __assign({}, props))
            };
            if (icons[type]) {
                return icons[type];
            }
        };
        _this.onAnimationStart = function (e) {
            if (_this.props.onAutoFill && e.animationName === 'onInputAutoFillStart') {
                _this.props.onAutoFill();
            }
        };
        return _this;
    }
    Input.prototype.componentDidMount = function () {
        if (this.$input && this.$input.current) {
            this.getInputWidth();
        }
    };
    Input.prototype.componentDidUpdate = function () {
        if (this.$input && this.$input.current) {
            if (this.$input.current.offsetWidth !== this.state.inputWidth) {
                this.getInputWidth();
            }
        }
    };
    Object.defineProperty(Input.prototype, "elEye", {
        get: function () {
            if (this.props.type === 'password') {
                var props = {
                    className: this.cn('input__icon-wrapper', {
                        'input__icon-wrapper_crossed': this.state.showPassword
                    }),
                    onClick: this.onClickShowPassword
                };
                return (React.createElement("div", __assign({}, props),
                    React.createElement(EyeIcon, { width: 24, height: 24, isCrossed: this.state.showPassword, className: this.cn('input__icon', 'input__icon_eye') })));
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Input.prototype, "elClear", {
        get: function () {
            if (this.props.onClear && this.props.value || this.props.pending) {
                var type = this.props.pending ? 'loading' : 'clear';
                return (React.createElement("div", { className: this.cn('input__icon-wrapper') }, this.elIconClear(type)));
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Input.prototype, "elIcon", {
        get: function () {
            var _a;
            if (this.props.icon) {
                var props = {
                    width: 24,
                    height: 24,
                    className: this.cn('input__icon', "input__icon_" + this.props.icon, (_a = {},
                        _a["input__icon_" + this.props.icon + "-active"] = this.state.focus,
                        _a))
                };
                var icons = {
                    location: React.createElement(LocationIcon, __assign({}, props)),
                    search: React.createElement(SearchIcon, __assign({}, props))
                };
                if (icons[this.props.icon]) {
                    return (React.createElement("div", { className: this.cn('input__icon-wrapper', 'input__icon-wrapper_left') }, icons[this.props.icon]));
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Input.prototype, "elInput", {
        get: function () {
            var passwordType = this.state.showPassword ? 'text' : 'password';
            var inputType = this.state.type === 'password' ? passwordType : this.state.type;
            var className = this.cn('input__input', this.props.classNames.input, {
                'input__input_invalid': this.props.invalid,
                'input__input_icon': this.props.icon,
                'input__input_icon-right': this.props.onClear || this.state.type === 'password' || this.props.pending,
                'input__input_both': this.props.onClear && this.state.type === 'password'
            });
            return (React.createElement("div", { className: this.cn('input__wrapper') },
                this.elIcon,
                React.createElement("input", { ref: this.$input, type: inputType, className: className, id: this.props.id, placeholder: this.props.placeholder, name: this.props.name, onChange: this.onChange, onFocus: this.onFocus, onBlur: this.onBlur, onKeyDown: this.onKeyDown, value: this.props.value, autoFocus: this.props.autoFocus, required: this.props.required && this.state.watch, disabled: this.props.disabled, readOnly: this.props.readonly, min: this.props.min, max: this.props.max, onAnimationStart: this.onAnimationStart }),
                this.elClear,
                this.elEye));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Input.prototype, "elLabel", {
        get: function () {
            var _a;
            if (this.props.label) {
                var className = this.cn('input__label', (_a = {},
                    _a["input__label_" + this.props.labelPosition] = this.props.labelPosition !== 'left',
                    _a), this.props.classNames.label);
                return (React.createElement("span", { className: className }, this.props.label));
            }
        },
        enumerable: true,
        configurable: true
    });
    Input.prototype.render = function () {
        var _a;
        var className = this.cn('input', this.props.className, (_a = {},
            _a["input_label-" + this.props.labelPosition] = this.props.labelPosition !== 'left',
            _a), this.props.classNames.wrapper);
        return (React.createElement("label", { className: className },
            this.elLabel,
            this.elInput));
    };
    Input.defaultProps = {
        labelPosition: 'left',
        type: 'text',
        classNames: {
            wrapper: '',
            input: '',
            label: ''
        }
    };
    return Input;
}(Component));
export default Input;
