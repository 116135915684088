var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import * as classnames from 'classnames/bind';
import style from './style.pcss';
import UI from 'component/ui';
var UserAnswer = /** @class */ (function (_super) {
    __extends(UserAnswer, _super);
    function UserAnswer(props) {
        var _this = _super.call(this, props) || this;
        _this.cn = classnames.bind(style);
        _this.onClickShowAll = function (e) {
            _this.setState({ showAll: !_this.state.showAll });
        };
        _this.state = {
            showAll: true
        };
        return _this;
    }
    UserAnswer.prototype.componentDidMount = function () {
        if (this.props.list.length > 3) {
            this.setState({ showAll: false });
        }
    };
    Object.defineProperty(UserAnswer.prototype, "elShowRes", {
        get: function () {
            var _this = this;
            if (this.state.showAll) {
                if (this.props.list) {
                    return (React.createElement("div", null, this.props.list.map(function (it) { return (React.createElement("div", { className: _this.cn('results__item'), key: it.answer.id },
                        React.createElement("div", { className: _this.cn('results__item-big'), dangerouslySetInnerHTML: { __html: it.question.name } }),
                        React.createElement("div", { className: _this.cn('results__item-small') }, it.answer.name))); })));
                }
            }
            else {
                var arrRes = this.props.list.slice(0, 3);
                if (this.props.list) {
                    return (React.createElement("div", null,
                        arrRes.map(function (it) { return (React.createElement("div", { className: _this.cn('results__item'), key: it.answer.id },
                            React.createElement("div", { className: _this.cn('results__item-big'), dangerouslySetInnerHTML: { __html: it.question.name } }),
                            React.createElement("div", { className: _this.cn('results__item-small') }, it.answer.name))); }),
                        React.createElement("div", { className: this.cn('results__show-all'), onClick: this.onClickShowAll }, "Show all")));
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    UserAnswer.prototype.render = function () {
        var className = this.cn('tooltip', this.props.className);
        return (React.createElement("div", { className: className },
            React.createElement(UI.Box, { className: this.cn('results__container'), padding: true },
                React.createElement(UI.BoxHeader, { className: this.cn('results__title') }, this.props.children),
                this.elShowRes)));
    };
    return UserAnswer;
}(Component));
export default UserAnswer;
