var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Fragment, PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import * as classnames from 'classnames/bind';
import map from './navigation-map';
import style from './navigation.pcss';
var Navigation = /** @class */ (function (_super) {
    __extends(Navigation, _super);
    function Navigation(props) {
        var _this = _super.call(this, props) || this;
        _this.cn = classnames.bind(style);
        // tslint:disable-next-line no-any
        _this.elIcon = function (_icon) {
            if (_icon) {
                return React.createElement(_icon, { className: _this.cn('navigation__icon') });
            }
        };
        _this.elRoot = function (item, index) {
            var props = {
                title: item.title,
                className: _this.cn('navigation__root'),
                children: (React.createElement(Fragment, null,
                    _this.elIcon(item.icon),
                    React.createElement("span", { className: _this.cn('navigation__root-title'), "data-index": index }, item.title)))
            };
            if (item.href) {
                return React.createElement("a", __assign({ href: item.href }, props));
            }
            else if (item.to) {
                return React.createElement(NavLink, __assign({ exact: item.exact, to: item.to, activeClassName: _this.cn('navigation__root_active') }, props));
            }
            return React.createElement("div", __assign({}, props));
        };
        _this.elChild = function (child) {
            if (_this.props.isChild && child) {
                return child.map(function (item, index) {
                    if (map[item]) {
                        var props = {
                            key: index,
                            title: map[item].title,
                            className: _this.cn('navigation__child'),
                            children: map[item].title
                        };
                        if (map[item].href) {
                            return React.createElement("a", __assign({ href: map[item].href }, props));
                        }
                        else if (map[item].to) {
                            // @ts-ignore
                            return React.createElement(NavLink, __assign({ to: map[item].to, exact: map[item].exact, activeClassName: _this.cn('navigation__child_active') }, props));
                        }
                        return React.createElement("div", __assign({}, props));
                    }
                });
            }
        };
        if (typeof _this.props.className === 'object' && !Array.isArray(_this.props.className)) {
            _this.cn = classnames.bind(__assign({}, style, _this.props.className));
        }
        return _this;
    }
    Object.defineProperty(Navigation.prototype, "className", {
        get: function () {
            if (typeof this.props.className === 'string') {
                return this.props.className;
            }
        },
        enumerable: true,
        configurable: true
    });
    Navigation.prototype.render = function () {
        var _this = this;
        if (this.props.children.length) {
            return (React.createElement("div", { className: this.cn('navigation', this.className) }, this.props.children.map(function (item, index) {
                if (map[item]) {
                    return (React.createElement(Fragment, { key: index },
                        _this.elRoot(map[item], index),
                        _this.elChild(map[item].child)));
                }
            })));
        }
        return null;
    };
    Navigation.defaultProps = {
        isChild: true,
        children: []
    };
    return Navigation;
}(PureComponent));
export default Navigation;
