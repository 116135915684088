var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as classnames from 'classnames/bind';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import cancelToken from 'component/core/cancel-token';
import i18n from 'component/core/i18n';
import Loader from 'component/loader';
import UI from 'component/ui';
import { getProposalssList } from 'component/api/polls';
import Refresh from 'component/icon/refresh';
import AddProposal from 'component/icon/add-proposal';
import Modal from 'component/modal';
import { key as keyUser } from 'component/user/reducer';
import Proposal from './proposal';
import ProposalCreate from './create';
import style from './style.pcss';
var Proposals = /** @class */ (function (_super) {
    __extends(Proposals, _super);
    function Proposals() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cn = classnames.bind(style);
        _this.cancelToken = cancelToken.create();
        _this.state = {
            list: [],
            total: 0,
            pending: false,
            listError: null,
            showModal: false
        };
        _this.getProposalList = function (refresh) {
            if (refresh === void 0) { refresh = false; }
            if (!_this.state.pending) {
                _this.setState({
                    pending: true,
                    list: refresh ? [] : _this.state.list
                }, function () {
                    getProposalssList({
                        cancelToken: _this.cancelToken.new()
                    })
                        .then(function (_a) {
                        var results = _a.results, count = _a.count;
                        _this.setState({
                            pending: false,
                            total: count,
                            listError: null,
                            list: __spread(_this.state.list, results)
                        });
                    })
                        .catch(function (error) {
                        if (!axios.isCancel(error)) {
                            console.error(error);
                            _this.setState({
                                listError: error.message,
                                pending: false
                            });
                        }
                    });
                });
            }
        };
        _this.onAddProposal = function (proposal) {
            _this.setState({
                list: __spread(_this.state.list, [proposal])
            });
        };
        _this.onEditProposal = function (newProposal) {
            var list = _this.state.list.map(function (proposal) {
                if (newProposal.id === proposal.id) {
                    return newProposal;
                }
                return proposal;
            });
            _this.setState({ list: list });
        };
        _this.onDeleteProposal = function (proposalId) {
            var list = _this.state.list.filter(function (proposal) { return proposal.id !== proposalId; });
            _this.setState({ list: list });
        };
        _this.onCloseModal = function () {
            _this.setState({ showModal: false });
        };
        _this.onClickShowModal = function () {
            _this.setState({ showModal: true });
        };
        _this.onClickRefresh = function (e) {
            e && e.preventDefault();
            _this.getProposalList(true);
        };
        return _this;
    }
    Proposals.prototype.componentDidMount = function () {
        this.getProposalList();
    };
    Proposals.prototype.componentWillUnmount = function () {
        this.cancelToken.remove();
    };
    Object.defineProperty(Proposals.prototype, "elModal", {
        get: function () {
            if (this.state.showModal) {
                return (React.createElement(Modal, { onClickClose: this.onCloseModal },
                    React.createElement(ProposalCreate, __assign({ onCloseModal: this.onCloseModal, onAddProposal: this.onAddProposal }, this.props))));
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Proposals.prototype, "elProposalList", {
        get: function () {
            var _this = this;
            if (!this.state.list.length && this.state.pending) {
                return React.createElement(Loader, null);
            }
            if (this.state.listError) {
                return React.createElement("span", { className: this.cn('proposals__error') }, this.state.listError);
            }
            if (!this.state.list.length) {
                return React.createElement("span", { className: this.cn('proposals__empty') }, i18n.t('route.proposals.empty'));
            }
            return (React.createElement("div", { className: this.cn('proposals__list') }, this.state.list.map(function (proposal, index) {
                return (React.createElement(Proposal, __assign({ key: proposal.id, index: index + 1, onEditProposal: _this.onEditProposal, onDeleteProposal: _this.onDeleteProposal }, proposal)));
            })));
        },
        enumerable: true,
        configurable: true
    });
    Proposals.prototype.render = function () {
        if (!this.props.userId) {
            return React.createElement(Redirect, { to: "/login" });
        }
        return (React.createElement(UI.Main, { className: this.cn('proposals') },
            React.createElement(Helmet, { title: i18n.t('helmet.title.proposals') }),
            React.createElement(UI.Box, { className: this.cn('proposals__container'), padding: true },
                React.createElement(UI.BoxHeader, { className: this.cn('proposals__header') },
                    i18n.t('route.proposals.label'),
                    React.createElement("div", { onClick: this.onClickRefresh, className: this.cn('proposals__header-el') },
                        React.createElement(Refresh, { width: 24, height: 24, className: this.cn('proposals__header-refresh') }),
                        i18n.t('route.proposals.refresh')),
                    React.createElement("div", { className: this.cn('proposals__header-el'), onClick: this.onClickShowModal },
                        React.createElement(AddProposal, { width: 24, height: 24, className: this.cn('proposals__header-add') }),
                        i18n.t('route.proposals.create'))),
                this.elProposalList),
            this.elModal));
    };
    return Proposals;
}(Component));
export default connect(function (store) { return ({
    userId: store[keyUser].pk
}); })(Proposals);
