var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { PureComponent } from 'react';
import * as classnames from 'classnames/bind';
import { Helmet } from 'react-helmet';
import i18n from 'component/core/i18n';
import UI from 'component/ui';
import style from './style.pcss';
var NotFound = /** @class */ (function (_super) {
    __extends(NotFound, _super);
    function NotFound() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cn = classnames.bind(style);
        return _this;
    }
    NotFound.prototype.render = function () {
        return (React.createElement(UI.Main, { className: this.cn('not-found') },
            React.createElement(Helmet, { title: i18n.t('route.not-found.header'), meta: [{
                        name: 'document-state',
                        content: 'static'
                    }] }),
            React.createElement("h1", { className: this.cn('not-found__header') }, i18n.t('route.not-found.header')),
            React.createElement("div", { className: this.cn('not-found__desc') }, i18n.t('route.not-found.desc'))));
    };
    return NotFound;
}(PureComponent));
export default NotFound;
