var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Base from './base';
var StatusOk = /** @class */ (function (_super) {
    __extends(StatusOk, _super);
    function StatusOk() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StatusOk.prototype.render = function () {
        return (React.createElement("svg", __assign({}, this.props),
            React.createElement("path", { d: "M6 6.327l5.073-5.072a.527.527 0 0 1 .763 0 .527.527 0 0 1 0 .763L6.382 7.473a.527.527 0 0 1-.764 0L3.436 5.29a.574.574 0 0 1 0-.71.553.553 0 0 1 .764-.054l1.8 1.8zM11.891 4.8a.56.56 0 0 0-.655-.436.56.56 0 0 0-.436.654c.546 2.646-1.173 5.264-3.818 5.782-2.646.518-5.264-1.173-5.782-3.818C.682 4.336 2.373 1.718 5.018 1.2a4.883 4.883 0 0 1 3.382.518c.273.164.6.055.736-.19a.529.529 0 0 0-.19-.737C6.055-.845 2.4.19.764 3.08c-1.637 2.892-.6 6.546 2.29 8.183 2.892 1.636 6.546.6 8.182-2.291C11.727 8.045 12 7.036 12 6c0-.382-.027-.79-.109-1.2z" })));
    };
    return StatusOk;
}(Base));
export default StatusOk;
