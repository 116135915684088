var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Base from './base';
var AddProposal = /** @class */ (function (_super) {
    __extends(AddProposal, _super);
    function AddProposal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AddProposal.prototype.render = function () {
        return (React.createElement("svg", __assign({}, this.props),
            React.createElement("path", { d: "M15.583 10.083a.92.92 0 0 1-.916.917h-2.75v2.75a.92.92 0 0 1-.917.917.92.92 0 0 1-.917-.917V11h-2.75a.92.92 0 0 1-.916-.917.92.92 0 0 1 .916-.916h2.75v-2.75A.92.92 0 0 1 11 5.5a.92.92 0 0 1 .917.917v2.75h2.75a.92.92 0 0 1 .916.916zm6.417 0c0 5.546-4.95 10.084-11 10.084-.78 0-1.558-.092-2.338-.23C6.6 21.221 4.262 21.909 1.833 22a.905.905 0 0 1-.825-.55c-.137-.367-.091-.733.184-1.008 1.283-1.284 1.375-2.521 1.466-3.575v-.23C.962 14.85 0 12.512 0 10.083 0 4.537 4.95 0 11 0s11 4.537 11 10.083zm-1.833 0c0-4.537-4.125-8.25-9.167-8.25s-9.167 3.713-9.167 8.25c0 2.109.871 4.125 2.384 5.546.229.184.32.504.275.78 0 .183-.046.366-.046.55a8.077 8.077 0 0 1-.688 2.933 10.594 10.594 0 0 0 4.125-1.742.8.8 0 0 1 .78-.137c6.004 1.466 11.504-2.705 11.504-7.93z" })));
    };
    return AddProposal;
}(Base));
export default AddProposal;
