var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Base from './base';
var Search = /** @class */ (function (_super) {
    __extends(Search, _super);
    function Search() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Search.prototype.render = function () {
        return (React.createElement("svg", __assign({}, this.props),
            React.createElement("path", { d: "M18.399,19.685l-3.892-3.892c-1.765,1.169-4.166,0.976-5.721-0.579c-1.775-1.775-1.775-4.653,0-6.428\n                    s4.653-1.775,6.428,0c1.555,1.555,1.748,3.956,0.579,5.721l3.892,3.892c3.279-3.929,3.075-9.782-0.614-13.47\n                    c-3.905-3.905-10.237-3.905-14.142,0c-3.905,3.905-3.905,10.237,0,14.142C8.617,22.76,14.471,22.964,18.399,19.685z M13.929,13.928\n                    c1.065-1.065,1.065-2.792,0-3.857c-1.065-1.065-2.792-1.065-3.857,0c-1.065,1.065-1.065,2.792,0,3.857\n                    C11.137,14.994,12.864,14.994,13.929,13.928z" })));
    };
    return Search;
}(Base));
export default Search;
