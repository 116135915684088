var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import * as classnames from 'classnames/bind';
import axios from 'axios';
import { connect } from 'react-redux';
import cancelToken from 'component/core/cancel-token';
import i18n from 'component/core/i18n';
import UI from 'component/ui';
import Input from 'component/form/input';
import ErrorIcon from 'component/icon/error';
import ActionBlock from 'component/action-block';
import Loader from 'component/loader';
import { updateProposal } from 'component/api/polls';
import { key as keyUser } from 'component/user/reducer';
import style from './edit.pcss';
var EditProposal = /** @class */ (function (_super) {
    __extends(EditProposal, _super);
    function EditProposal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cn = classnames.bind(style);
        _this.cancelToken = cancelToken.create();
        _this.state = {
            error: null,
            pending: false,
            link: _this.props.link || '',
            name: _this.props.name || ''
        };
        _this.requestUpdateProposal = function () {
            _this.setState({
                pending: true
            }, function () {
                updateProposal(String(_this.props.id), {
                    cancelToken: _this.cancelToken.new(),
                    data: {
                        owner: _this.props.userId,
                        name: _this.state.name,
                        doc_link: _this.state.link
                    }
                })
                    .then(function (proposal) {
                    _this.setState({
                        pending: false,
                        error: null
                    });
                    _this.props.onEditProposal && _this.props.onEditProposal(proposal);
                    _this.props.onCloseModal && _this.props.onCloseModal();
                })
                    .catch(function (error) {
                    if (!axios.isCancel(error)) {
                        console.error(error);
                        _this.setState({
                            error: error.error,
                            pending: false
                        });
                    }
                });
            });
        };
        _this.onSubmit = function (e) {
            e.preventDefault();
            _this.requestUpdateProposal();
        };
        _this.onChangeInputs = function (name) { return function (value) {
            var _a;
            _this.setState((_a = {},
                _a[name] = value,
                _a));
        }; };
        _this.onCloseModal = function () {
            _this.props.onCloseModal && _this.props.onCloseModal();
        };
        return _this;
    }
    Object.defineProperty(EditProposal.prototype, "elActionBlock", {
        get: function () {
            var props = {
                className: this.cn('edit-proposal__action-block', 'edit-proposal__action-block_page'),
                primaryButton: {
                    children: 'Save',
                    type: 'submit',
                    disabled: this.state.pending
                },
                secondaryButton: {
                    children: 'Cancel',
                    onClick: this.onCloseModal,
                    disabled: this.state.pending
                }
            };
            return React.createElement(ActionBlock, __assign({}, props));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditProposal.prototype, "elLoader", {
        get: function () {
            if (this.state.pending) {
                return (React.createElement("div", { className: this.cn('edit-proposal__loader-wrapper') },
                    React.createElement(Loader, null)));
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditProposal.prototype, "elError", {
        get: function () {
            if (this.state.error) {
                return (React.createElement("div", { className: this.cn('edit-proposal__error') },
                    React.createElement(ErrorIcon, { width: 24, height: 24, className: this.cn('edit-proposal__error-icon') }),
                    React.createElement("span", { className: this.cn('edit-proposal__error-text') }, this.state.error)));
            }
        },
        enumerable: true,
        configurable: true
    });
    EditProposal.prototype.render = function () {
        return (React.createElement(UI.Main, null,
            React.createElement(UI.Box, { padding: true, className: this.cn('edit-proposal') },
                React.createElement(UI.BoxHeader, { className: this.cn('edit-proposal__head') }, "Edit proposal \"" + this.props.name + "\""),
                React.createElement("form", { onSubmit: this.onSubmit },
                    React.createElement("div", { className: this.cn('edit-proposal__content') },
                        React.createElement("div", { className: this.cn('edit-proposal__block') },
                            React.createElement("div", { className: this.cn('edit-proposal__input-wrapper') },
                                React.createElement(Input, { label: i18n.t('route.proposals.input.name.label'), value: this.state.name, onChange: this.onChangeInputs('name'), autoFocus: true, required: true, classNames: {
                                        wrapper: this.cn('edit-proposal__name-wrapper'),
                                        label: this.cn('edit-proposal__input-label')
                                    } })),
                            React.createElement("div", { className: this.cn('edit-proposal__input-wrapper') },
                                React.createElement(Input, { label: i18n.t('route.proposals.input.link.label'), value: this.state.link, onChange: this.onChangeInputs('link'), required: true, classNames: {
                                        wrapper: this.cn('edit-proposal__link-wrapper'),
                                        label: this.cn('edit-proposal__input-label')
                                    } })))),
                    this.elActionBlock),
                this.elLoader,
                this.elError)));
    };
    return EditProposal;
}(Component));
export default connect(function (store) { return ({
    userId: store[keyUser].pk
}); })(EditProposal);
