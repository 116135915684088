exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".login__ui-main__3AWLj {\n            justify-content: center;\n        }\n\n        .login__ui-box__mvlWR {\n            max-width: 620px;\n            margin-top: 100px;\n        }\n\n        .login__content__1cy73 {\n            display: flex;\n            flex-direction: column;\n            box-sizing: border-box;\n        }\n\n        .login__button-submit__jxJIu {\n            display: block;\n            width: 100%;\n            height: 60px;\n            line-height: 60px;\n            font-size: 20px;\n        }\n", "",{"version":3,"sources":["style.pcss"],"names":[],"mappings":"AAIQ;YACI,uBAAuB;QAC3B;;QAEA;YACI,gBAAgB;YAChB,iBAAiB;QACrB;;QAEA;YACI,aAAa;YACb,sBAAsB;YACtB,sBAAsB;QAC1B;;QAEA;YACI,cAAc;YACd,WAAW;YACX,YAAY;YACZ,iBAAiB;YACjB,eAAe;QACnB","file":"style.pcss","sourcesContent":[":local {\n\n    .login {\n\n        &__ui-main {\n            justify-content: center;\n        }\n\n        &__ui-box {\n            max-width: 620px;\n            margin-top: 100px;\n        }\n\n        &__content {\n            display: flex;\n            flex-direction: column;\n            box-sizing: border-box;\n        }\n\n        &__button-submit {\n            display: block;\n            width: 100%;\n            height: 60px;\n            line-height: 60px;\n            font-size: 20px;\n        }\n    }\n}\n"]}]);

// Exports
exports.locals = {
	"login__ui-main": "login__ui-main__3AWLj",
	"login__ui-box": "login__ui-box__mvlWR",
	"login__content": "login__content__1cy73",
	"login__button-submit": "login__button-submit__jxJIu"
};