var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { PureComponent } from 'react';
import * as classnames from 'classnames/bind';
import style from './style.pcss';
var Radio = /** @class */ (function (_super) {
    __extends(Radio, _super);
    function Radio() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cn = classnames.bind(style);
        _this.onChange = function (e) {
            if (e && _this.props.onChange) {
                _this.props.onChange(e);
            }
        };
        return _this;
    }
    Radio.prototype.render = function () {
        return (React.createElement("label", { className: this.cn('radio', this.props.className) },
            React.createElement("input", { className: this.cn('radio__input'), type: "radio", id: String(this.props.id), disabled: this.props.disabled, checked: this.props.value, name: this.props.name, onChange: this.onChange }),
            React.createElement("span", { className: this.cn('radio__label', {
                    'radio__label_disabled': this.props.disabled,
                    'radio__label_checked': this.props.value
                }) }, this.props.label)));
    };
    Radio.defaultProps = {
        value: false
    };
    return Radio;
}(PureComponent));
export default Radio;
