var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Fragment, Component } from 'react';
import * as classnames from 'classnames/bind';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
import i18n from 'component/core/i18n';
import Header from 'component/header';
import HeaderMobile from 'component/header/mobile';
import HeaderTablet from 'component/header/tablet';
import { key as keyDeviceInfo } from 'component/device-info/reducer';
import ico from './img/favicon.ico';
import style from './style.pcss';
var Layout = /** @class */ (function (_super) {
    __extends(Layout, _super);
    function Layout() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cn = classnames.bind(style);
        _this.state = {
            isSubMenu: false
        };
        _this.onLanguageChanged = function (lang) {
            console.info('Change language to %s', lang);
            _this.forceUpdate();
        };
        return _this;
    }
    Layout.getDerivedStateFromProps = function (props, state) {
        var isSubMenu = false;
        if (props.location) {
            if (props.location.pathname.startsWith('/social')) {
                isSubMenu = true;
            }
            else if (props.location.pathname.startsWith('/service')) {
                isSubMenu = true;
            }
        }
        return __assign({}, state, { isSubMenu: isSubMenu });
    };
    Layout.prototype.componentDidMount = function () {
        i18n.on('languageChanged', this.onLanguageChanged);
    };
    Layout.prototype.componentWillUnmount = function () {
        i18n.off('languageChanged', this.onLanguageChanged);
    };
    Object.defineProperty(Layout.prototype, "elHeader", {
        get: function () {
            if (this.props.isMobile) {
                return React.createElement(HeaderMobile, null);
            }
            else if (this.props.isTablet) {
                return React.createElement(HeaderTablet, null);
            }
            return React.createElement(Header, null);
        },
        enumerable: true,
        configurable: true
    });
    Layout.prototype.render = function () {
        return (React.createElement(Fragment, null,
            React.createElement(Helmet, { link: [{
                        rel: 'shortcut icon',
                        href: ico
                    }] }),
            this.elHeader,
            React.createElement("div", { className: this.cn('layout', {
                    'layout_sub-menu': this.state.isSubMenu
                }) }, this.props.children)));
    };
    return Layout;
}(Component));
export default compose(withRouter, connect(function (store) { return ({
    isMobile: store[keyDeviceInfo].mobile,
    isTablet: store[keyDeviceInfo].tablet
}); }))(Layout);
