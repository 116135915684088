import ajv from './validation';
import api from './';
export var main = function (method, endpoint, config, schema, endpointBase) {
    if (config === void 0) { config = {}; }
    if (endpointBase === void 0) { endpointBase = '/api/'; }
    if (!endpoint) {
        console.error('No `main` endpoint specified');
    }
    return api[method]("" + endpointBase + endpoint + "/", config)
        .then(function (payload) {
        if (payload && schema) {
            var valid = ajv.validate(schema, payload);
            if (!valid) {
                console.warn('API `main` invalid format! Errors: %o, Response: %o, Schema: `%s`', ajv.errors, payload, schema);
            }
        }
        return payload;
    });
};
export var getPollsList = function (config) {
    return main('get', 'polls/poll', config, 'GetPollsList');
};
export var getPoll = function (id, config) {
    return main('get', "polls/poll/" + id, config, 'GetPoll');
};
export var sendAnswer = function (config) {
    return main('post', 'polls/result', config);
};
export var pollRegister = function (config) {
    return main('post', 'polls/poll-reg', config);
};
export var getProgressDetail = function (config) {
    return main('get', 'polls/progress', config, 'GetProgressList');
};
export var getPublicDetail = function (config) {
    return main('get', 'polls/public-result', config);
};
export var getNotRegistered = function (config) {
    return main('get', 'polls/no-reg', config, 'GetProgressList');
};
export var getProposalssList = function (config) {
    return main('get', 'proposals/proposal', config);
};
export var createProposal = function (config) {
    return main('post', 'proposals/proposal', config);
};
export var removeProposal = function (id, config) {
    return main('deleteMethod', "proposals/proposal/" + id, config);
};
export var addProposalLike = function (config) {
    return main('post', 'proposals/proposal-vote', config);
};
export var removeProposalLike = function (id, config) {
    return main('deleteMethod', "proposals/proposal-vote/" + id, config);
};
export var updateProposal = function (id, config) {
    return main('patch', "proposals/proposal/" + id, config);
};
export default {
    main: main,
    getPollsList: getPollsList,
    getProgressDetail: getProgressDetail,
    getPoll: getPoll,
    sendAnswer: sendAnswer,
    pollRegister: pollRegister,
    getProposalssList: getProposalssList,
    addProposalLike: addProposalLike,
    removeProposalLike: removeProposalLike,
    getNotRegistered: getNotRegistered,
    getPublicDetail: getPublicDetail
};
