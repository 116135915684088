import api from './';
import ajv from './validation';
export var user = function (method, endpoint, config, schema) {
    if (config === void 0) { config = {}; }
    if (!endpoint) {
        console.error('No `user` endpoint specified');
    }
    return api[method]("/api/" + endpoint + "/", config)
        .then(function (payload) {
        if (payload && schema) {
            var valid = ajv.validate(schema, payload);
            if (!valid) {
                console.warn('API `user` invalid format! Errors: %o, Response: %o, Schema: `%s`', ajv.errors, payload, schema);
            }
        }
        return payload;
    });
};
export var userSelfInfoV2 = function (config) {
    return user('get', 'rest-auth/user', config, 'UserSelfInfo');
};
export var login = function (config) {
    return user('post', 'rest-auth/login', config);
};
export var logout = function (config) {
    return user('post', 'rest-auth/logout', config);
};
export default {
    user: user,
    login: login,
    logout: logout,
    userSelfInfoV2: userSelfInfoV2
};
