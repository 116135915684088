var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Base from './base';
var TrashBin = /** @class */ (function (_super) {
    __extends(TrashBin, _super);
    function TrashBin() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TrashBin.prototype.render = function () {
        return (React.createElement("svg", __assign({}, this.props),
            React.createElement("path", { d: "M15.273 3h-3.637v-.75c0-1.238-.981-2.25-2.181-2.25h-2.91c-1.2 0-2.181 1.012-2.181 2.25V3H.727C.327 3 0 3.337 0 3.75c0 .412.327.75.727.75h1.455v.037l.654 11.326C2.91 17.063 3.855 18 5.018 18h5.964c1.164 0 2.109-.938 2.182-2.137l.654-11.325V4.5h1.455c.4 0 .727-.338.727-.75a.741.741 0 0 0-.727-.75zm-9.455-.75c0-.413.327-.75.727-.75h2.91c.4 0 .727.337.727.75V3H5.818v-.75zm5.891 13.537c-.036.413-.327.713-.727.713H5.018c-.4 0-.69-.3-.727-.713L3.636 4.5h8.728l-.655 11.287z" })));
    };
    return TrashBin;
}(Base));
export default TrashBin;
