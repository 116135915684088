var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { PureComponent } from 'react';
import * as classnames from 'classnames/bind';
import { connect } from 'react-redux';
import axios from 'axios';
import IconLoader from 'component/icon/loader';
import IconLogout from 'component/icon/logout';
import i18n from 'component/core/i18n';
import { logout } from 'component/api/user';
import { reset } from './../actions';
import { key as keyUser } from './../reducer';
import style from './menu.pcss';
var Menu = /** @class */ (function (_super) {
    __extends(Menu, _super);
    function Menu() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cn = classnames.bind(style);
        _this.cancelTokenSource = axios.CancelToken.source();
        _this.state = {
            pending: false
        };
        _this.onClickLogout = function (e) {
            e && e.preventDefault();
            if (!_this.state.pending) {
                _this.setState({
                    pending: true
                }, function () {
                    logout({
                        cancelToken: _this.cancelTokenSource.token
                    })
                        .then(function () {
                        _this.props.dispatch(reset());
                        _this.props.onClickLogout && _this.props.onClickLogout(e);
                    })
                        .catch(function (error) {
                        if (!axios.isCancel(error)) {
                            console.error(error);
                        }
                    });
                });
            }
        };
        return _this;
    }
    Menu.prototype.componentWillUnmount = function () {
        this.cancelTokenSource.cancel('Component is unmount! Request `logout` abort.');
    };
    Object.defineProperty(Menu.prototype, "elButtonLogout", {
        get: function () {
            if (this.props.pk) {
                if (this.state.pending) {
                    return (React.createElement("div", { className: this.cn('menu__item') },
                        React.createElement(IconLoader, { width: 24, height: 24, className: this.cn('menu__icon') }),
                        React.createElement("span", { className: this.cn('menu__title') }, i18n.t('components.user.menu.buttons.logout'))));
                }
                return (React.createElement("a", { href: "#logout", onClick: this.onClickLogout, className: this.cn('menu__item') },
                    React.createElement(IconLogout, { width: 24, height: 24, className: this.cn('menu__icon') }),
                    React.createElement("span", { className: this.cn('menu__title') }, i18n.t('components.user.menu.buttons.logout'))));
            }
        },
        enumerable: true,
        configurable: true
    });
    Menu.prototype.render = function () {
        if (!this.props.pk) {
            return null;
        }
        return (React.createElement("div", { className: this.cn('menu', this.props.className) }, this.elButtonLogout));
    };
    return Menu;
}(PureComponent));
export default connect(function (store) { return ({
    pk: store[keyUser].pk,
    first_name: store[keyUser].first_name,
    last_name: store[keyUser].last_name,
    profile_name: store[keyUser].profile_name,
    is_superuser: store[keyUser].is_superuser,
    birth_date: store[keyUser].birth_date,
    is_staff: store[keyUser].is_staff
}); })(Menu);
