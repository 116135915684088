var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import * as classnames from 'classnames/bind';
import Button from 'component/button';
import style from './style.pcss';
var ActionBlock = /** @class */ (function (_super) {
    __extends(ActionBlock, _super);
    function ActionBlock() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cn = classnames.bind(style);
        return _this;
    }
    Object.defineProperty(ActionBlock.prototype, "secondButton", {
        get: function () {
            if (this.props.secondaryButton) {
                return (React.createElement(Button, __assign({ isSecondary: true }, this.props.secondaryButton, { className: this.cn('action-block__button') })));
            }
        },
        enumerable: true,
        configurable: true
    });
    ActionBlock.prototype.render = function () {
        return (React.createElement("div", { className: this.cn('action-block', this.props.className) },
            React.createElement(Button, __assign({}, this.props.primaryButton, { className: this.cn('action-block__button') })),
            this.secondButton));
    };
    return ActionBlock;
}(Component));
export default ActionBlock;
