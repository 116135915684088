var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import * as classnames from 'classnames/bind';
import IconClose from 'component/icon/close';
import style from './index.pcss';
var Modal = /** @class */ (function (_super) {
    __extends(Modal, _super);
    function Modal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cn = classnames.bind(style);
        _this.onClickClose = function (e) {
            if (_this.props.onClickClose) {
                _this.props.onClickClose(e);
            }
        };
        _this.onKeyDown = function (e) {
            if (e && e.keyCode === 27) {
                _this.onClickClose();
            }
        };
        _this.onClickContent = function (e) {
            e && e.stopPropagation();
        };
        return _this;
    }
    Object.defineProperty(Modal.prototype, "elButtonClose", {
        get: function () {
            if (this.props.onClickClose) {
                return (React.createElement("div", { className: this.cn('modal__button-close') },
                    React.createElement(IconClose, { height: 22, width: 22, onClick: this.onClickClose, className: this.cn('modal__icon-close') })));
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Modal.prototype, "elContent", {
        get: function () {
            return (React.createElement("div", { className: this.cn('modal', this.props.className), onClick: this.onClickClose, onKeyDown: this.onKeyDown },
                React.createElement("div", { className: this.cn('modal__content', {
                        'modal__content_center': this.props.isCenterContent
                    }), onClick: this.onClickContent },
                    this.elButtonClose,
                    this.props.children)));
        },
        enumerable: true,
        configurable: true
    });
    Modal.prototype.render = function () {
        return createPortal(this.elContent, document.body);
    };
    return Modal;
}(Component));
export default Modal;
