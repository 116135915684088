var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Base from './base';
var CheckConnection = /** @class */ (function (_super) {
    __extends(CheckConnection, _super);
    function CheckConnection() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CheckConnection.prototype.render = function () {
        return (React.createElement("svg", __assign({}, this.props),
            React.createElement("path", { d: "M6.257 8.571a1.72 1.72 0 0 0-1.714 1.715A1.72 1.72 0 0 0 6.257 12a1.72 1.72 0 0 0 1.714-1.714A1.72 1.72 0 0 0 6.257 8.57zm0 2.286a.573.573 0 0 1-.571-.571c0-.315.257-.572.571-.572.314 0 .571.257.571.572a.573.573 0 0 1-.57.571zM8.943 7.03a.564.564 0 0 1-.4.971.564.564 0 0 1-.4-.171L7.8 7.486a2.226 2.226 0 0 0-3.086 0l-.343.343a.6.6 0 0 1-.743 0 .58.58 0 0 1-.057-.8l.343-.343a3.363 3.363 0 0 1 4.714 0l.315.343zm1.714-2.286a.6.6 0 0 1 0 .743.58.58 0 0 1-.8.057l-.114-.086a4.932 4.932 0 0 0-7 0l-.086.086a.6.6 0 0 1-.743 0 .58.58 0 0 1-.057-.8l.086-.114a6.087 6.087 0 0 1 8.6 0l.114.114zm1.886-1.886a.573.573 0 0 1-.572.572.564.564 0 0 1-.4-.172c-1.371-1.343-3.2-2.114-5.114-2.114h-.4A7.27 7.27 0 0 0 .943 3.257a.6.6 0 0 1-.743 0 .58.58 0 0 1-.057-.8A8.346 8.346 0 0 1 6.057 0h.4c2.229 0 4.343.886 5.914 2.457a.564.564 0 0 1 .172.4z" })));
    };
    return CheckConnection;
}(Base));
export default CheckConnection;
