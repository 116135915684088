var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { userSelfInfoV2 } from 'component/api/user';
import { key } from './reducer';
import { set, reset } from './actions';
var UserProvider = /** @class */ (function (_super) {
    __extends(UserProvider, _super);
    function UserProvider() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cancelToken = axios.CancelToken.source();
        _this.token = {
            selfInfo: axios.CancelToken.source(),
            targetActions: axios.CancelToken.source()
        };
        _this._requestUserSelfInfo = function () {
            userSelfInfoV2({
                cancelToken: _this.token.selfInfo.token
            })
                .then(function (payload) {
                var action = {
                    pk: payload.pk,
                    first_name: payload.first_name,
                    last_name: payload.last_name,
                    birth_date: payload.birth_date,
                    profile_name: payload.profile_name,
                    is_superuser: payload.is_superuser,
                    is_staff: payload.is_staff
                };
                _this.props.dispatch(set(action));
            })
                .catch(function (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                    if (_this.props.isAuth) {
                        _this.props.dispatch(reset());
                    }
                }
            });
        };
        return _this;
    }
    UserProvider.prototype.componentDidMount = function () {
        this._requestUserSelfInfo();
    };
    UserProvider.prototype.render = function () {
        return this.props.children;
    };
    return UserProvider;
}(Component));
export default connect(function (store) { return ({
    isAuth: !!store[key].pk
}); })(UserProvider);
