var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import * as classnames from 'classnames/bind';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import moment from 'moment';
import cancelToken from 'component/core/cancel-token';
import i18n from 'component/core/i18n';
import Loader from 'component/loader';
import LazyList from 'component/lazy-list';
import UI from 'component/ui';
import { getPollsList } from 'component/api/polls';
import { key as keyUser } from 'component/user/reducer';
import style from './style.pcss';
var PublicProgressList = /** @class */ (function (_super) {
    __extends(PublicProgressList, _super);
    function PublicProgressList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cn = classnames.bind(style);
        _this.cancelToken = cancelToken.create();
        _this.state = {
            list: [],
            total: 0,
            pending: false,
            listError: null
        };
        _this.getPollList = function () {
            _this.setState({ pending: true }, function () {
                getPollsList({
                    cancelToken: _this.cancelToken.new()
                })
                    .then(function (_a) {
                    var results = _a.results, count = _a.count;
                    _this.setState({
                        pending: false,
                        total: count,
                        list: __spread(_this.state.list, results)
                    });
                })
                    .catch(function (error) {
                    if (!axios.isCancel(error)) {
                        console.error(error);
                        _this.setState({
                            listError: error.message,
                            pending: false
                        });
                    }
                });
            });
        };
        return _this;
    }
    PublicProgressList.prototype.componentDidMount = function () {
        this.getPollList();
    };
    PublicProgressList.prototype.componentWillUnmount = function () {
        this.cancelToken.remove();
    };
    Object.defineProperty(PublicProgressList.prototype, "elPollsList", {
        get: function () {
            var _this = this;
            if (!this.state.list.length && this.state.pending) {
                return React.createElement(Loader, null);
            }
            if (this.state.listError) {
                return React.createElement("span", { className: this.cn('polls__error') }, this.state.listError);
            }
            if (!this.state.list.length) {
                return React.createElement("span", { className: this.cn('polls__empty') }, i18n.t('route.progress.empty'));
            }
            return (React.createElement(LazyList, { onClick: this.getPollList, className: this.cn('polls__lazy-list'), total: this.state.total }, this.state.list.map(function (item, index) { return (React.createElement("a", { key: item.id, className: _this.cn('polls__item'), href: "/public-progress/" + item.id },
                React.createElement("div", { className: _this.cn('polls__item-sub') },
                    React.createElement("div", { className: _this.cn('polls__item-number') }, index + 1),
                    React.createElement("div", { className: _this.cn('polls__item-name') }, item.name)),
                React.createElement("div", { className: _this.cn('polls__item-info') },
                    React.createElement("div", { className: _this.cn('polls__item-date') },
                        moment(item.start).format('DD.MM.YY'),
                        " - ",
                        moment(item.end).format('DD.MM.YY')),
                    React.createElement("div", { className: _this.cn('polls__item-status', {
                            'polls__item-in-progress': item.status === 'In progress',
                            'polls__item-finished': item.status === 'Finished'
                        }) }, item.status)))); })));
        },
        enumerable: true,
        configurable: true
    });
    PublicProgressList.prototype.render = function () {
        if (!this.props.userId) {
            return React.createElement(Redirect, { to: "/login" });
        }
        if (this.props.userId && !this.props.isStaff) {
            return React.createElement(Redirect, { to: "/404" });
        }
        return (React.createElement(UI.Main, { className: this.cn('polls') },
            React.createElement(Helmet, { title: i18n.t('helmet.title.progress-list') }),
            React.createElement(UI.Box, { padding: true },
                React.createElement(UI.BoxHeader, { className: this.cn('polls__pagename-container') }, i18n.t('route.progress.label')),
                this.elPollsList)));
    };
    return PublicProgressList;
}(Component));
export default connect(function (store) { return ({
    userId: store[keyUser].pk,
    isStaff: store[keyUser].is_staff
}); })(PublicProgressList);
