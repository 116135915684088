var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Fragment, Component } from 'react';
import * as classnames from 'classnames/bind';
import { connect } from 'react-redux';
import axios from 'axios';
import Radio from 'component/form/radio';
import cancelToken from 'component/core/cancel-token';
import { key as keyUser } from 'component/user/reducer';
import { key as keyDeviceInfo } from 'component/device-info/reducer';
import { sendAnswer } from 'component/api/polls';
import i18n from 'component/core/i18n';
import CheckConnection from 'component/icon/check-connection';
import StatusOk from 'component/icon/status-ok';
import Timer from 'component/icon/timer';
import NotVoted from 'component/icon/not-voted';
import style from './question.pcss';
var Question = /** @class */ (function (_super) {
    __extends(Question, _super);
    function Question(props) {
        var _this = _super.call(this, props) || this;
        _this.cn = classnames.bind(style);
        _this.cancelToken = cancelToken.create();
        _this.onClickAnswer = function (answerId) { return function () {
            _this.setState({
                pending: true,
                displayAnswer: answerId,
                error: false
            }, function () {
                sendAnswer({
                    data: {
                        question: _this.props.pk,
                        answer: answerId,
                        user: _this.props.userId,
                        server: 1
                    },
                    cancelToken: _this.cancelToken.new()
                })
                    .then(function () {
                    _this.setState({
                        answer: _this.state.displayAnswer,
                        pending: false,
                        error: false
                    }, function () {
                        _this.props.onChangeAnswer && _this.props.onChangeAnswer(_this.props.pk);
                    });
                })
                    .catch(function (error) {
                    if (!axios.isCancel(error)) {
                        console.error(error);
                        _this.setState({
                            error: true,
                            pending: false,
                            displayAnswer: _this.state.answer
                        });
                    }
                });
            });
        }; };
        var answer = props.variants.find(function (variant) { return variant.checked; });
        _this.state = {
            answer: answer ? answer.id : null,
            displayAnswer: answer ? answer.id : null,
            error: false,
            pending: false
        };
        return _this;
    }
    Question.prototype.componentWillUnmount = function () {
        this.cancelToken.remove();
    };
    Object.defineProperty(Question.prototype, "elAnswers", {
        get: function () {
            var _this = this;
            if (this.props.variants.length) {
                return this.props.variants.map(function (variant) {
                    return (React.createElement(Radio, { key: variant.id, value: _this.state.displayAnswer === variant.id, id: variant.id, label: variant.name, name: String(_this.props.pk), onChange: _this.onClickAnswer(variant.id), className: _this.cn('question__answer'), disabled: _this.state.pending || !_this.props.can_edit }));
                });
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Question.prototype, "elStatusText", {
        get: function () {
            if (this.state.pending) {
                return (React.createElement(Fragment, null,
                    React.createElement(Timer, { width: 10, height: 12, viewBox: "0 0 12 12" }),
                    this.props.isMobile ? null : i18n.t('route.polls.awaiting')));
            }
            if (this.state.error) {
                return (React.createElement(Fragment, null,
                    React.createElement(CheckConnection, { width: 12, height: 12, viewBox: "0 0 12 12" }),
                    this.props.isMobile ? null : i18n.t('route.polls.connection')));
            }
            if (this.state.answer) {
                return (React.createElement(Fragment, null,
                    React.createElement(StatusOk, { width: 12, height: 12, viewBox: "0 0 12 12" }),
                    this.props.isMobile ? null : i18n.t('route.polls.confirmed')));
            }
            return (React.createElement(Fragment, null,
                React.createElement(NotVoted, { width: 12, height: 12, viewBox: "0 0 12 12" }),
                this.props.isMobile ? null : i18n.t('route.polls.not-voted')));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Question.prototype, "elStatus", {
        get: function () {
            return (React.createElement("div", { className: this.cn('question__status', {
                    'question__status_pending': this.state.pending,
                    'question__status_error': this.state.error,
                    'question__status_success': this.state.answer && !this.state.pending,
                    'question__status_default': !this.state.answer && !this.state.error && !this.state.pending
                }) }, this.elStatusText));
        },
        enumerable: true,
        configurable: true
    });
    Question.prototype.render = function () {
        return (React.createElement("div", { className: this.cn('question') },
            React.createElement("span", { className: this.cn('question__index') }, this.props.index),
            React.createElement("div", { className: this.cn('question__title'), dangerouslySetInnerHTML: { __html: this.props.name } }),
            React.createElement("div", { className: this.cn('question__content') },
                React.createElement("div", { className: this.cn('question__answers') }, this.elAnswers),
                this.elStatus)));
    };
    return Question;
}(Component));
export default connect(function (store) { return ({
    userId: store[keyUser].pk,
    isMobile: store[keyDeviceInfo].mobile
}); })(Question);
