var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import * as classnames from 'classnames/bind';
import style from './progress-bar.pcss';
var ProgressBar = /** @class */ (function (_super) {
    __extends(ProgressBar, _super);
    function ProgressBar() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cn = classnames.bind(style);
        return _this;
    }
    Object.defineProperty(ProgressBar.prototype, "elBarPeacesList", {
        get: function () {
            var _this = this;
            return Object.keys(this.props.answeredList).map(function (id, index) {
                return (React.createElement("div", { key: index, className: _this.cn('progress-bar__peace', {
                        'progress-bar__peace_success': _this.props.answeredList[Number(id)],
                        'progress-bar__peace_skipped': !_this.props.answeredList[Number(id)]
                    }) }));
            });
        },
        enumerable: true,
        configurable: true
    });
    ProgressBar.prototype.render = function () {
        return (React.createElement("div", { className: this.cn('progress-bar') }, this.elBarPeacesList));
    };
    return ProgressBar;
}(Component));
export default ProgressBar;
