var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import * as classnames from 'classnames/bind';
import { connect } from 'react-redux';
import axios from 'axios';
import cancelToken from 'component/core/cancel-token';
import i18n from 'component/core/i18n';
import IconThumbUp from 'component/icon/thumb-up';
import IconEdit from 'component/icon/post';
import IconTrash from 'component/icon/trash-bin';
import { key as keyUser } from 'component/user/reducer';
import { addProposalLike, removeProposalLike } from 'component/api/polls';
import Modal from 'component/modal';
import ProposalDelete from './delete';
import ProposalEdit from './edit';
import style from './proposal.pcss';
var Proposal = /** @class */ (function (_super) {
    __extends(Proposal, _super);
    function Proposal(props) {
        var _this = _super.call(this, props) || this;
        _this.cn = classnames.bind(style);
        _this.cancelToken = cancelToken.create();
        _this.requestAddLike = function () {
            _this.setState({
                pending: true,
                voted: true,
                likesCount: _this.state.likesCount + 1
            }, function () {
                addProposalLike({
                    data: {
                        user: _this.props.userId,
                        proposal: _this.props.id
                    },
                    cancelToken: _this.cancelToken.new()
                })
                    .then(function (_a) {
                    var voteId = _a.id;
                    _this.setState({
                        pending: false,
                        voteId: voteId
                    });
                })
                    .catch(function (error) {
                    if (!axios.isCancel(error)) {
                        console.error(error);
                        _this.setState({
                            error: true,
                            voted: false,
                            likesCount: _this.state.likesCount - 1,
                            pending: false
                        });
                    }
                });
            });
        };
        _this.requestRemoveLike = function () {
            _this.setState({
                pending: true,
                voted: false,
                likesCount: _this.state.likesCount - 1
            }, function () {
                removeProposalLike(String(_this.state.voteId), { cancelToken: _this.cancelToken.new() })
                    .then(function () {
                    _this.setState({
                        pending: false
                    });
                })
                    .catch(function (error) {
                    if (!axios.isCancel(error)) {
                        console.error(error);
                        _this.setState({
                            error: true,
                            voted: true,
                            likesCount: _this.state.likesCount + 1,
                            pending: false
                        });
                    }
                });
            });
        };
        // Like
        _this.onClickLike = function (e) {
            e && e.preventDefault();
            if (!_this.state.pending) {
                _this.state.voted ? _this.requestRemoveLike() : _this.requestAddLike();
            }
        };
        // Edit
        _this.onClickEdit = function (e) {
            e && e.preventDefault();
            _this.setState({
                showEditModal: true
            });
        };
        _this.onCloseEditModal = function () {
            _this.setState({
                showEditModal: false
            });
        };
        _this.onEditProposal = function (proposal) {
            _this.props.onEditProposal && _this.props.onEditProposal(proposal);
        };
        // Delete
        _this.onClickDelete = function (e) {
            e && e.preventDefault();
            _this.setState({
                showConfirm: true
            });
        };
        _this.onCloseConfirm = function () {
            _this.setState({
                showConfirm: false
            });
        };
        _this.onDeleteProposal = function () {
            _this.onCloseConfirm();
            _this.props.onDeleteProposal && _this.props.onDeleteProposal(_this.props.id);
        };
        _this.state = {
            voted: props.voted,
            likesCount: props.num_votes,
            voteId: props.vote_id || null,
            showConfirm: false,
            pending: false,
            confirmPending: false,
            showEditModal: false,
            error: false
        };
        return _this;
    }
    Proposal.prototype.componentWillUnmount = function () {
        this.cancelToken.remove();
    };
    Object.defineProperty(Proposal.prototype, "elDeleteModal", {
        get: function () {
            if (this.state.showConfirm) {
                return (React.createElement(Modal, { onClickClose: this.onCloseConfirm },
                    React.createElement(ProposalDelete, { id: this.props.id, proposalName: this.props.name, onCloseModal: this.onCloseConfirm, onDeleteProposal: this.onDeleteProposal })));
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Proposal.prototype, "elEditModal", {
        get: function () {
            if (this.state.showEditModal) {
                return (React.createElement(Modal, { onClickClose: this.onCloseEditModal },
                    React.createElement(ProposalEdit, { id: this.props.id, name: this.props.name, link: this.props.doc_link, onCloseModal: this.onCloseEditModal, onEditProposal: this.onEditProposal })));
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Proposal.prototype, "name", {
        get: function () {
            if (this.props.doc_link) {
                return (React.createElement("a", { href: this.props.doc_link, target: "_blank", className: this.cn('proposal__item-name-link') }, this.props.name));
            }
            return (React.createElement("div", { className: this.cn('proposal__item-name') }, this.props.name));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Proposal.prototype, "elEdit", {
        get: function () {
            if (this.props.owner === this.props.userId && this.props.can_edit) {
                return (React.createElement("div", { onClick: this.onClickEdit, className: this.cn('proposal__item-info-el') },
                    React.createElement(IconEdit, { width: 24, height: 24, className: this.cn('proposal__edit', 'proposal__icon') })));
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Proposal.prototype, "elDelete", {
        get: function () {
            if (this.props.owner === this.props.userId && this.props.can_delete) {
                return (React.createElement("div", { onClick: this.onClickDelete, className: this.cn('proposal__item-info-el') },
                    React.createElement(IconTrash, { width: 24, height: 24, className: this.cn('proposal__trash', 'proposal__icon') })));
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Proposal.prototype, "elLike", {
        get: function () {
            return (React.createElement("div", { onClick: this.onClickLike, className: this.cn('proposal__item-info-el') },
                React.createElement(IconThumbUp, { width: 24, height: 24, className: this.cn('proposal__thumb-up', 'proposal__icon', {
                        'proposal__thumb-up_active': this.state.voted
                    }) }),
                React.createElement("span", { className: this.cn('proposal__likes-count', {
                        'proposal__likes-count_active': this.state.voted
                    }) }, this.state.likesCount)));
        },
        enumerable: true,
        configurable: true
    });
    Proposal.prototype.render = function () {
        return (React.createElement("div", { className: this.cn('proposal') },
            React.createElement("div", { className: this.cn('proposal__item-user-info') },
                React.createElement("div", { className: this.cn('proposal__item-sub') },
                    React.createElement("div", { className: this.cn('proposal__item-number') }, this.props.index),
                    this.name),
                React.createElement("div", { className: this.cn('proposal__item-owner') },
                    React.createElement("span", null, "Created by"),
                    this.props.userId === this.props.owner ? i18n.t('route.proposals.you') : this.props.owner_name)),
            React.createElement("div", { className: this.cn('proposal__item-info') },
                this.elEdit,
                this.elDelete,
                this.elLike),
            this.elDeleteModal,
            this.elEditModal));
    };
    return Proposal;
}(Component));
export default connect(function (store) { return ({
    userId: store[keyUser].pk
}); })(Proposal);
