var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Base from './base';
var ThumbUp = /** @class */ (function (_super) {
    __extends(ThumbUp, _super);
    function ThumbUp() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ThumbUp.prototype.render = function () {
        return (React.createElement("svg", __assign({}, this.props),
            React.createElement("path", { d: "M2.9,8.7v12.5c0,0.5,0.4,0.8,0.8,0.8h4.2c0.5,0,0.8-0.4,0.8-0.8v-0.8h8.2c0.9,0,1.7-0.5,2.1-1.2c1.4-2.3,2.1-5,2.1-7.7v-0.2\n  c0-1.4-1.1-2.5-2.5-2.5l-2.8,0c1.1-2.7,1-4.9-0.5-6.2C15,2.2,14.6,2,14.1,2c-1.1,0-2,0.9-2,2c-0.1,1.9-1,3.6-2.6,4.7L8.7,9.2V8.7\n  c0-0.5-0.4-0.8-0.8-0.8H3.7C3.2,7.8,2.9,8.2,2.9,8.7z M10.5,10c2-1.4,3.2-3.6,3.3-6c0-0.2,0.1-0.4,0.3-0.4c0,0,0.1,0,0.2,0\n  c1.2,1,0.5,3.1,0,4.3c-0.4,0.9,0,1.8,0.9,2.2c0.2,0.1,0.4,0.1,0.7,0.1h2.8c0.5,0,0.8,0.4,0.8,0.8v0.2c0,2.4-0.7,4.8-1.9,6.8\n  c-0.2,0.2-0.4,0.4-0.7,0.4H8.7v-7.5L10.5,10L10.5,10z M4.5,9.5H7v10.8H4.5V9.5z" })));
    };
    return ThumbUp;
}(Base));
export default ThumbUp;
