var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Component } from 'react';
import { connect } from 'react-redux';
import throttle from 'lodash.throttle';
import { set } from './actions';
var DeviceInfo = /** @class */ (function (_super) {
    __extends(DeviceInfo, _super);
    function DeviceInfo(props) {
        var _this = _super.call(this, props) || this;
        _this._onResize = throttle(function () {
            _this.props.dispatch(set(window.innerWidth));
        }, 300);
        if (window) {
            _this.props.dispatch(set(window.innerWidth, window.devicePixelRatio));
        }
        return _this;
    }
    DeviceInfo.prototype.componentDidMount = function () {
        window.addEventListener('resize', this._onResize);
    };
    DeviceInfo.prototype.componentWillUnmount = function () {
        window.removeEventListener('resize', this._onResize);
    };
    DeviceInfo.prototype.render = function () {
        return this.props.children;
    };
    return DeviceInfo;
}(Component));
export default connect()(DeviceInfo);
