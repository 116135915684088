exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".svg-icon__3UYnW {\n        width: 24px;\n        height: 24px;\n        fill: #979797;\n    }\n\n        .svg-icon__3UYnW > g {\n            fill: #ffffff;\n        }\n\n        .svg-icon_loader__2AIJF {\n            width: 40px;\n            height: 40px;\n        }\n\n        .svg-icon_coat__3I5OE {\n            fill: #cc9f53;\n        }\n\n        .svg-icon_coat__3I5OE > g {\n                fill: #011e41;\n            }\n\n        .svg-icon_avatar__2ojRB {\n            fill: #65819e;\n        }\n\n        .svg-icon_avatar__2ojRB > g { fill: #ffffff; }\n\n        .svg-icon_yellow__PzpCQ { fill: #f7b322; }\n", "",{"version":3,"sources":["style.pcss"],"names":[],"mappings":"AAEI;QACI,WAAW;QACX,YAAY;QACZ,aAAiB;IA0BrB;;QAxBI;YACI,aAAkB;QACtB;;QAEA;YACI,WAAW;YACX,YAAY;QAChB;;QAEA;YACI,aAAwB;QAK5B;;QAHI;gBACI,aAAsB;YAC1B;;QAGJ;YACI,aAAwB;QAG5B;;QADI,8BAAQ,aAAkB,EAAE;;QAGhC,0BAAW,aAAmB,EAAE","file":"style.pcss","sourcesContent":[":local {\n\n    .svg-icon {\n        width: 24px;\n        height: 24px;\n        fill: var(--gray);\n\n        & > g {\n            fill: var(--white);\n        }\n\n        &_loader {\n            width: 40px;\n            height: 40px;\n        }\n\n        &_coat {\n            fill: var(--coat-orange);\n\n            & > g {\n                fill: var(--coat-blue);\n            }\n        }\n\n        &_avatar {\n            fill: var(--avatar-blue);\n\n            & > g { fill: var(--white); }\n        }\n\n        &_yellow { fill: var(--yellow); }\n    }\n}\n"]}]);

// Exports
exports.locals = {
	"svg-icon": "svg-icon__3UYnW",
	"svg-icon_loader": "svg-icon_loader__2AIJF",
	"svg-icon_coat": "svg-icon_coat__3I5OE",
	"svg-icon_avatar": "svg-icon_avatar__2ojRB",
	"svg-icon_yellow": "svg-icon_yellow__PzpCQ"
};