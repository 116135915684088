var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Base from './base';
var Refresh = /** @class */ (function (_super) {
    __extends(Refresh, _super);
    function Refresh() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Refresh.prototype.render = function () {
        return (React.createElement("svg", __assign({}, this.props),
            React.createElement("path", { d: "M10.975 19.954c-4.939 0-8.98-4.04-8.98-8.98a1 1 0 0 0-.997-.997 1 1 0 0 0-.998.998c-.05 6.086 4.89 11.024 10.925 11.074C16.961 22.1 21.95 17.16 22 11.124 22.05 5.088 17.16.05 11.075.05a10.825 10.825 0 0 0-8.082 3.442V.998A1 1 0 0 0 1.996 0a1 1 0 0 0-.998.998v4.988a1 1 0 0 0 .998.998h4.988a1 1 0 0 0 .998-.998 1 1 0 0 0-.998-.998H4.29c3.293-3.691 8.98-3.99 12.671-.698 3.692 3.293 3.991 8.98.699 12.67a9 9 0 0 1-6.685 2.994z" })));
    };
    return Refresh;
}(Base));
export default Refresh;
