var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { handleActions } from 'redux-actions-helpers';
import { set, reset } from './actions';
var initialState = {
    pk: 0,
    first_name: null,
    last_name: null,
    birth_date: null,
    profile_name: null,
    is_superuser: false,
    is_staff: false
};
export var key = 'user';
export default handleActions((_a = {},
    _a[set] = function (state, action) {
        if (action.payload) {
            var result = __assign({}, state, { pk: action.payload.pk || null, first_name: action.payload.first_name || null, last_name: action.payload.last_name || null, birth_date: action.payload.birth_date || null, profile_name: action.payload.profile_name || null, is_superuser: action.payload.is_superuser || false, is_staff: action.payload.is_staff || false });
            try {
                sessionStorage.setItem('user', JSON.stringify(result));
            }
            catch (error) {
                console.error(error);
            }
            return result;
        }
        return state;
    },
    _a[reset] = function () {
        sessionStorage.removeItem('user');
        return __assign({}, initialState);
    },
    _a), { initialState: initialState });
