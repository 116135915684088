var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import * as classnames from 'classnames/bind';
import style from './box-header.pcss';
var BoxHeader = /** @class */ (function (_super) {
    __extends(BoxHeader, _super);
    function BoxHeader() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cn = classnames.bind(style);
        return _this;
    }
    BoxHeader.prototype.render = function () {
        var props = {
            'className': this.cn('box-header', this.props.className),
            'data-count': this.props.count,
            'children': (React.createElement("strong", { className: this.cn('box-header__children') }, this.props.children))
        };
        if (this.props.to) {
            return React.createElement(Link, __assign({ to: this.props.to }, props));
        }
        return React.createElement("h6", __assign({}, props));
    };
    return BoxHeader;
}(PureComponent));
export default BoxHeader;
