var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import * as classnames from 'classnames/bind';
import axios from 'axios';
import cancelToken from 'component/core/cancel-token';
import UI from 'component/ui';
import ErrorIcon from 'component/icon/error';
import ActionBlock from 'component/action-block';
import Loader from 'component/loader';
import { removeProposal } from 'component/api/polls';
import style from './delete.pcss';
var DeleteProposal = /** @class */ (function (_super) {
    __extends(DeleteProposal, _super);
    function DeleteProposal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cn = classnames.bind(style);
        _this.cancelToken = cancelToken.create();
        _this.state = {
            error: false,
            pending: false
        };
        _this.requestDeleteProposal = function () {
            if (!_this.state.pending) {
                _this.setState({
                    pending: true
                }, function () {
                    removeProposal(String(_this.props.id), { cancelToken: _this.cancelToken.new() })
                        .then(function () {
                        _this.setState({
                            pending: false
                        });
                        _this.props.onDeleteProposal && _this.props.onDeleteProposal(_this.props.id);
                    })
                        .catch(function (error) {
                        if (!axios.isCancel(error)) {
                            console.error(error);
                            _this.setState({
                                error: true,
                                pending: false
                            });
                        }
                    });
                });
            }
        };
        _this.onClickCancel = function () {
            _this.props.onCloseModal && _this.props.onCloseModal();
        };
        return _this;
    }
    Object.defineProperty(DeleteProposal.prototype, "elActionBlock", {
        get: function () {
            var props = {
                className: this.cn('delete-proposal__action-block', 'delete-proposal__action-block_page'),
                primaryButton: {
                    children: 'Delete',
                    onClick: this.requestDeleteProposal,
                    disabled: this.state.pending
                },
                secondaryButton: {
                    children: 'Cancel',
                    onClick: this.onClickCancel,
                    disabled: this.state.pending
                }
            };
            return React.createElement(ActionBlock, __assign({}, props));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeleteProposal.prototype, "elLoader", {
        get: function () {
            if (this.state.pending) {
                return (React.createElement("div", { className: this.cn('delete-proposal__loader-wrapper') },
                    React.createElement(Loader, null)));
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeleteProposal.prototype, "elError", {
        get: function () {
            if (this.state.error) {
                return (React.createElement("div", { className: this.cn('delete-proposal__error') },
                    React.createElement(ErrorIcon, { width: 24, height: 24, className: this.cn('delete-proposal__error-icon') }),
                    React.createElement("span", { className: this.cn('delete-proposal__error-text') }, "An error accured. Try again later")));
            }
        },
        enumerable: true,
        configurable: true
    });
    DeleteProposal.prototype.render = function () {
        return (React.createElement(UI.Main, null,
            React.createElement(UI.Box, { padding: true, className: this.cn('delete-proposal') },
                React.createElement(UI.BoxHeader, { className: this.cn('delete-proposal__head') }, "Delete proposal \"" + this.props.proposalName + "\" ?"),
                this.elActionBlock,
                this.elLoader,
                this.elError)));
    };
    return DeleteProposal;
}(Component));
export default DeleteProposal;
