var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from 'axios';
import ajv from './validation';
export var request = function (method, url, config) {
    if (config === void 0) { config = {}; }
    return axios(__assign({}, config, { method: method === 'deleteMethod' ? 'delete' : method, url: url }))
        .then(function (response) {
        if (response.data) {
            if (response.data) {
                return response.data;
            }
            else {
                return void (0);
            }
        }
        return response;
    }, function (error) {
        if (error.response) {
            if (error.response.data) {
                var valid = ajv.validate('error', error.response.data);
                if (!valid) {
                    console.warn('API invalid format! Errors: %o, Response: %o, Schema: `error`', ajv.errors, error.response.data);
                }
                throw error.response.data;
            }
            else {
                return void (0);
            }
        }
        throw error;
    });
};
export var get = function (url, config) {
    if (config === void 0) { config = {}; }
    return request('get', url, config);
};
export var post = function (url, config) {
    if (config === void 0) { config = {}; }
    return request('post', url, config);
};
export var patch = function (url, config) {
    if (config === void 0) { config = {}; }
    return request('patch', url, config);
};
export var deleteMethod = function (url, config) {
    if (config === void 0) { config = {}; }
    return request('deleteMethod', url, config);
};
export default {
    request: request,
    deleteMethod: deleteMethod,
    get: get,
    post: post,
    patch: patch
};
