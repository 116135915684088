var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as classnames from 'classnames/bind';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { key as keyUser } from 'component/user/reducer';
import history from 'component/core/history';
import { key as keyDeviceInfo } from 'component/device-info/reducer';
import { login, userSelfInfoV2 } from 'component/api/user';
import { set, reset } from 'component/user/actions';
import i18n from 'component/core/i18n';
import Input from 'component/form/input';
import Button from 'component/button';
import UI from 'component/ui';
import style from './style.pcss';
var Login = /** @class */ (function (_super) {
    __extends(Login, _super);
    function Login() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cn = classnames.bind(style);
        _this.cancelTokenSource = {
            login: axios.CancelToken.source(),
            userSelfInfoV2: axios.CancelToken.source()
        };
        _this.state = {
            pending: false,
            autoFillEmail: false,
            autoFillPassword: false,
            username: '',
            password: '',
            error: null
        };
        _this.onChangeInputEmail = function (username) {
            _this.setState({
                username: username,
                autoFillEmail: false
            });
        };
        _this.onAutoFillInputEmail = function () {
            if (!_this.state.autoFillEmail) {
                _this.setState({ autoFillEmail: true });
            }
        };
        _this.onAutoFillInputPassword = function () {
            if (!_this.state.autoFillPassword) {
                _this.setState({ autoFillPassword: true });
            }
        };
        _this.onChangeInputPassword = function (password) {
            _this.setState({
                password: password,
                autoFillPassword: false
            });
        };
        _this.onSubmit = function (e) {
            e && e.preventDefault();
            if (_this.isValid && !_this.state.pending) {
                _this.setState({
                    error: null,
                    pending: true
                }, function () {
                    login({
                        cancelToken: _this.cancelTokenSource.login.token,
                        data: {
                            username: _this.state.username,
                            password: _this.state.password
                        }
                    })
                        .then(function () {
                        userSelfInfoV2({
                            cancelToken: _this.cancelTokenSource.userSelfInfoV2.token
                        })
                            .then(function (payload) {
                            history.goBack();
                            _this.props.dispatch(set(payload));
                        })
                            .catch(function (error) {
                            if (!axios.isCancel(error)) {
                                _this.setState({
                                    error: 'An unexpected error has occurred. :(',
                                    pending: false
                                }, function () {
                                    _this.props.dispatch(reset());
                                });
                            }
                        });
                    })
                        .catch(function (error) {
                        if (!axios.isCancel(error)) {
                            _this.setState({
                                error: error.message,
                                pending: false
                            });
                        }
                    });
                });
            }
        };
        return _this;
    }
    Login.prototype.componentWillUnmount = function () {
        this.cancelTokenSource.login.cancel('Component is unmount! Request `login` abort.');
        this.cancelTokenSource.userSelfInfoV2.cancel('Component is unmount! Request `userSelfInfo` abort.');
    };
    Object.defineProperty(Login.prototype, "isValid", {
        get: function () {
            // @TODO: Validation and runtime field validation
            return !!(this.state.username && this.state.password) || (this.state.autoFillEmail && this.state.autoFillPassword);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Login.prototype, "isDisabled", {
        get: function () {
            return !this.isValid || this.state.pending;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Login.prototype, "elError", {
        get: function () {
            if (this.state.error) {
                return React.createElement("span", { className: this.cn('login__form-error') }, this.state.error);
            }
        },
        enumerable: true,
        configurable: true
    });
    Login.prototype.render = function () {
        if (this.props.isAuth) {
            return React.createElement(Redirect, { to: "/" });
        }
        return (React.createElement(UI.Main, { className: this.cn('login__ui-main') },
            React.createElement(Helmet, { title: i18n.t('helmet.title.login'), meta: [{
                        name: 'document-state',
                        content: 'static'
                    }] }),
            React.createElement(UI.Box, { padding: true, className: this.cn('login__ui-box') },
                React.createElement("div", { className: this.cn('login__content') },
                    React.createElement("h2", { className: this.cn('login__header') }, i18n.t('route.login.header')),
                    React.createElement("form", { className: this.cn('login__form'), onSubmit: this.onSubmit },
                        React.createElement(Input, { labelPosition: "top", name: "username", type: "username", className: this.cn('login__input'), onChange: this.onChangeInputEmail, onAutoFill: this.onAutoFillInputEmail, autoFocus: true, value: this.state.username, label: i18n.t('route.login.input.username.label') }),
                        React.createElement(Input, { labelPosition: "top", className: this.cn('login__input'), onAutoFill: this.onAutoFillInputPassword, onChange: this.onChangeInputPassword, value: this.state.password, type: "password", name: "password", label: i18n.t('route.login.input.password.label') }),
                        this.elError,
                        React.createElement(Button, { type: "submit", isYellow: true, className: this.cn('login__button-submit'), isLoading: this.state.pending, disabled: this.isDisabled }, i18n.t('route.login.button.login')))))));
    };
    return Login;
}(PureComponent));
export default connect(function (store) { return ({
    isAuth: !!store[keyUser].pk,
    isMobile: store[keyDeviceInfo].mobile
}); })(Login);
