import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import store from 'component/core/store';
import DeviceInfo from 'component/device-info';
import UserProvider from 'component/user/provider';
import Routes from 'route/index';
import config from 'config';
var startApp = function () {
    render((React.createElement(Provider, { store: store },
        React.createElement(UserProvider, null,
            React.createElement(DeviceInfo, null,
                React.createElement(Routes, null))))), document.getElementById('app'), function () {
        console.info('App version: %s', __VERSION__);
        if (config.sentry) {
            Sentry.init({
                dsn: config.sentry,
                debug: __DEVELOPMENT__,
                release: __VERSION__,
                environment: process.env.NODE_ENV,
                maxBreadcrumbs: 20,
                enabled: !__DEVELOPMENT__,
                integrations: [
                    new Sentry.Integrations.Breadcrumbs({
                        console: false
                    })
                ]
            });
        }
    });
};
if (!__DEVELOPMENT__) {
    startApp();
}
else {
    // Synthetic waiting loading css (style-loader chunk)
    window.onload = startApp;
}
