var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Base from './base';
var Logout = /** @class */ (function (_super) {
    __extends(Logout, _super);
    function Logout() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Logout.prototype.render = function () {
        return (React.createElement("svg", __assign({}, this.props, { viewBox: "0 0 24 24" }),
            React.createElement("path", { d: "M19.25 7.04a.87.87 0 0 0-1.167.08c-.25.28-.25.76 0 1.04l1.084 1.04h-3.834c-.458 0-.833.36-.833.8 0 .44.375.8.833.8h3.834l-1.084 1.04A.789.789 0 0 0 18 12.96a.87.87 0 0 0 1.167.08c.041-.04.083-.04.083-.08l2.5-2.4a.752.752 0 0 0 0-1.12l-2.5-2.4z" }),
            React.createElement("path", { d: "M2 15.4c0 1.4.875 2.68 2.167 3.24l7.458 3.28c.125.04.208.08.333.08.459 0 .834-.36.834-.8V18h.833c1.375 0 2.5-1.08 2.5-2.4V14c0-.44-.375-.8-.833-.8-.459 0-.834.36-.834.8v1.6c0 .44-.375.8-.833.8h-.833V6.8a.79.79 0 0 0-.5-.72L6.667 3.6h7c.458 0 .833.36.833.8V6c0 .44.375.8.833.8.459 0 .834-.36.834-.8V4.4c0-1.32-1.125-2.4-2.5-2.4H4.5C3.125 2 2 3.08 2 4.4v11zm1.667-11c0-.12.041-.24.083-.32l7.417 3.24v12.64L4.875 17.2c-.708-.32-1.208-1-1.208-1.8v-11z" })));
    };
    return Logout;
}(Base));
export default Logout;
