import Ajv from 'ajv';
import error from './schemas/error.json';
// User
import UserSelfInfo from './schemas/user/user-self-info.json';
// Polls
import GetPollsList from './schemas/polls/get-polls-list.json';
import GetProgressList from './schemas/polls/get-progress-list.json';
import GetPoll from './schemas/polls/get-poll.json';
var ajv = new Ajv({
    nullable: true,
    allErrors: true
});
ajv
    .addSchema(error, 'error')
    // User
    .addSchema(UserSelfInfo, 'UserSelfInfo')
    // Polls
    .addSchema(GetPollsList, 'GetPollsList')
    .addSchema(GetProgressList, 'GetProgressList')
    .addSchema(GetPoll, 'GetPoll');
export default ajv;
