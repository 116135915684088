var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createBrowserHistory } from 'history';
var reLang = /^(\/[a-z]{2}(?:-[a-z]{4})?)(?:\/|$)/;
var basename = '/';
if (reLang.test(window.location.pathname)) {
    var match = window.location.pathname.match(reLang);
    if (match && match[1]) {
        basename = match[1];
    }
}
var history = createBrowserHistory({ basename: basename });
if (history.location.state && history.location.state.modal) {
    history.replace(history.location.pathname, __assign({}, history.location.state, { modal: false }));
}
history.listen(function (location, action) {
    if (action === 'PUSH' || action === 'REPLACE' && (!location.state || !location.state.noScroll)) {
        window.scrollTo(0, 0);
    }
});
export default history;
