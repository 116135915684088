var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Fragment, PureComponent } from 'react';
import * as classnames from 'classnames/bind';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import i18n from 'component/core/i18n';
import IconClose from 'component/icon/close';
import IconBurger from 'component/icon/burger';
import IconLogoText from 'component/icon/logo-text';
import UserAvatar from 'component/user/avatar';
import { key as keyUser } from 'component/user/reducer';
import UserMobileMenu from 'component/user/mobile/menu';
// import LocationMobileSearch from 'component/location/mobile/search';
import Button from 'component/button';
// import SearchSuggest from 'component/search/suggest';
import Navigation from './../navigation';
import style from './index.pcss';
var Mobile = /** @class */ (function (_super) {
    __extends(Mobile, _super);
    function Mobile() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cn = classnames.bind(style);
        _this.state = {
            panel: null
        };
        _this.onClickChangePanel = function (value) { return function (e) {
            e && e.preventDefault();
            if (_this.state.panel !== value) {
                _this.setState({
                    panel: value
                });
            }
        }; };
        return _this;
    }
    Mobile.prototype.componentDidUpdate = function (props) {
        if (props.location !== this.props.location) {
            this.setState({
                panel: null
            });
        }
    };
    Object.defineProperty(Mobile.prototype, "userName", {
        get: function () {
            if (this.props.profile_name) {
                return this.props.profile_name;
            }
            if (this.props.first_name && this.props.last_name) {
                return this.props.first_name + " " + this.props.last_name;
            }
            if (this.props.first_name && !this.props.last_name) {
                return this.props.first_name;
            }
            if (!this.props.first_name && this.props.last_name) {
                return this.props.last_name;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Mobile.prototype, "elUser", {
        get: function () {
            if (this.props.isAuth) {
                return (React.createElement(UserAvatar, { size: 32, className: this.cn('header__avatar'), onClick: this.onClickChangePanel('profile') }));
            }
            return (React.createElement(Button, { isYellow: true, 
                // tslint:disable-next-line max-line-length
                href: "https://asgardia.space/o/authorize/?response_type=code&client_id=tHqGekigBSRcQAfEBcyBHNafxu2sc7bpyVhYSjJm&redirect_uri=https://vote-parliament.asgardia.space/api/asg-oauth2/", className: this.cn('header__button-login') }, i18n.t('components.header.buttons.login')));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Mobile.prototype, "elPanel", {
        get: function () {
            switch (this.state.panel) {
                case 'navigation':
                    return (React.createElement(Fragment, null,
                        React.createElement("strong", { className: this.cn('header__panel-label', 'header__panel-label_menu') }, i18n.t('components.header.menu')),
                        React.createElement(IconClose, { width: 24, height: 24, className: this.cn('header__icon-close'), onClick: this.onClickChangePanel(null) })));
                case 'profile':
                    return (React.createElement(Fragment, null,
                        React.createElement("div", { className: this.cn('header__panel-label') },
                            React.createElement(UserAvatar, { size: 32, className: this.cn('header__avatar'), onClick: this.onClickChangePanel('profile') }),
                            this.userName),
                        React.createElement(IconClose, { width: 24, height: 24, className: this.cn('header__icon-close'), onClick: this.onClickChangePanel(null) })));
                default:
                    return (React.createElement(Fragment, null,
                        React.createElement(IconBurger, { width: 24, height: 24, className: this.cn('header__icon-burger'), onClick: this.onClickChangePanel('navigation') }),
                        React.createElement("a", { href: "/", className: this.cn('header__logo') },
                            React.createElement(IconLogoText, { width: 130, height: 30, className: this.cn('header__icon-logo') })),
                        this.elUser));
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Mobile.prototype, "elContent", {
        get: function () {
            switch (this.state.panel) {
                case 'navigation':
                    var routes = [
                        '/en/',
                        '/en/proposals',
                        '/en/public-progress'
                    ];
                    if (this.props.isStaff) {
                        routes.push('/en/progress');
                    }
                    return (React.createElement(Navigation, { className: this.cn('header__navigation'), children: routes }));
                case 'profile':
                    return (React.createElement(UserMobileMenu, { onClickLogout: this.onClickChangePanel(null) }));
                default:
                    return null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Mobile.prototype.render = function () {
        return (React.createElement("div", { className: this.cn('header', {
                'header_full': this.state.panel
            }) },
            React.createElement("div", { className: this.cn('header__panel') }, this.elPanel),
            this.elContent));
    };
    return Mobile;
}(PureComponent));
export default compose(withRouter, connect(function (store) { return ({
    profile_name: store[keyUser].profile_name,
    first_name: store[keyUser].first_name,
    last_name: store[keyUser].last_name,
    isAuth: !!store[keyUser].pk,
    isStaff: store[keyUser].is_staff
}); }))(Mobile);
