var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Base from './base';
var User = /** @class */ (function (_super) {
    __extends(User, _super);
    function User() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    User.prototype.render = function () {
        return (React.createElement("svg", __assign({}, this.props, { className: this.cn(this.className, 'svg-icon_avatar') }),
            React.createElement("circle", { cx: "12", cy: "12", r: "12" }),
            React.createElement("g", null,
                React.createElement("path", { d: "M15.7,17.9c0.1,0.6,0.7,1.2,1.6,1.6c-1.2,0.8-2.5,1.4-4,1.7c-0.5,0.1-1-0.2-1-0.8v-1c0-1,0.6-1.9,1.4-2.2 c0.7-0.4,1.4-0.8,2.2-1.4c0,0.2,0,0.5,0.1,0.7C15.7,16.9,15.6,17.4,15.7,17.9 M8.2,17.9c0.1-0.5,0-1-0.4-1.4l0.1-0.1L8,15.7 c0.6,0.6,1.2,1.1,2,1.4c1,0.2,1.4,1.2,1.4,2.2v1.1c0,0.5-0.4,0.8-0.8,0.8c-1.4-0.2-2.9-0.7-4-1.7C7.4,19.1,8,18.5,8.2,17.9 M6.6,12.6c1.2,1.8,3.1,2.9,5.3,3c2.2,0,4.1-1.1,5.3-2.9c-0.8,1.9-2.4,4.3-5.3,4.3S7.4,14.6,6.6,12.6 M6.1,11.2l-0.2-1 c0-0.5-0.1-0.8,0-1.2c0.1-1.3,1-2.5,2.3-3c2.4-1,5.2-1,7.6,0C17,6.5,17.9,7.7,18,9c0.1,0.5,0,1-0.2,1.4c-1,2.4-3.6,2.8-4.6,2.8 c0-0.5-0.2-0.7-0.7-0.7h-1.2c-0.4,0-0.7,0.4-0.7,0.7v0.2c0,0.5,0.2,0.7,0.7,0.7h1.2c0.4,0,0.7-0.2,0.7-0.6c1.6,0,3.1-0.7,4.2-1.8 c-1.1,2-3.1,3.2-5.4,3.4C9.4,15,7.1,13.4,6.1,11.2 M5.6,8.2V7.4C6.1,5.8,8.9,4.6,12,4.6s5.8,1.2,6.2,2.9l0.1,0.7v0.1 C18,7.1,17.2,6.1,16,5.8c-2.6-1-5.4-1-7.9,0C6.8,6.2,5.9,7.2,5.6,8.3 M11.9,2.5c2.6,0,4.9,1.4,5.8,3.6c-1.6-1.4-3.6-2.2-5.8-2 C9.4,4.2,7.2,4.9,6.1,6.2C7.1,4,9.4,2.5,11.9,2.5 M20.9,18.7L20,18.5c-2.2-0.7-3.4-1.4-3.7-2.2c-0.1-0.4-0.1-0.7,0.1-1.2 c0.6-0.7,1-1.6,1.3-2.5c0.7,0,1.2-0.5,1.4-1.2l0.2-0.8c0.1-0.6-0.1-1.3-0.6-1.6V7.4c-0.7-3.1-3.5-5.4-6.7-5.3C8.4,2,5.4,4.7,5,8.2 V9c-0.5,0.4-0.7,1-0.6,1.6l0.2,0.8c0.1,0.7,0.7,1.2,1.4,1.2c0.2,0.7,0.7,1.7,1.3,2.5c0.1,0.4,0.2,0.7,0.1,1.1 c-0.2,0.7-1.4,1.4-3.6,2.2l-1.8,0.7l0.2,0.2l0.7-0.2L4,18.7c1.9-0.6,3-1.2,3.6-1.9c0.1,0.2,0.2,0.6,0.1,1c-0.1,0.7-1,1.4-2.3,1.8 l-0.7,0.2c-0.5,0.1-0.8,0.4-1.2,0.6l0.1,0.4l1.2-0.6l0.7-0.2l0.6-0.2c3.4,2.6,8,2.6,11.4,0l0.7,0.2l0.6,0.2l1.2,0.6l0.2-0.2 c-0.4-0.2-0.8-0.5-1.3-0.7l-0.7-0.2c-1.3-0.5-2.2-1.1-2.3-1.8c-0.1-0.4,0-0.7,0.2-1c0.5,0.7,1.7,1.3,3.6,1.9l1.6,0.6 c0-0.1,0.1-0.2,0.2-0.2C21.4,19,21.1,18.8,20.9,18.7" }))));
    };
    return User;
}(Base));
export default User;
